import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CLAIMS_API } from '@shared/constants/claims/claims.constants';
import { IvmSyncPost } from '@modules/new-claims/models/table.model';
import { AddEditClaimPayment } from '@shared/models/payment-management.model';
import { Api } from '@shared/constants/api.constants';
import { Api_URL } from '@shared/constants/url.constants';
import { AddContactPesonRequest } from '@shared/models/contact-person.model';
import { ContractSparteResponse } from '@shared/models/claims-management.model';

@Injectable({
  providedIn: 'root',
})
export class ClaimsManagementApiService {
  constructor(private http: HttpClient) {}

  getContactPersonOverviewData(claim_id: any): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        CLAIMS_API.CLAIMS_API_URL +
        '/' +
        claim_id +
        '/contact_persons'
    );
  }

  addClaimContactPerson(request: AddContactPesonRequest): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.CLAIM_CONTACT_PERSON,
      request
    );
  }

  deleteContactPerson(cp_id: number): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + CLAIMS_API.CLAIMS_CONTACT_PERSONS + '/' + cp_id
    );
  }

  deleteClaim(claim_id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + CLAIMS_API.CLAIMS_API_URL + '/' + claim_id
    );
  }

  getClaimIVMSync(claim_id: any): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + CLAIMS_API.CLAIMS_IVM_FLAG + '?id=' + claim_id
    );
  }

  postClaimIVMSync(payload: IvmSyncPost): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + CLAIMS_API.CLAIMS_IVM_FLAG,
      payload
    );
  }

  getPaymentsOverviewData(claim_id: any): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        CLAIMS_API.CLAIMS_PAYMENT_OVERVIEW +
        '/' +
        claim_id +
        '/payment_management'
    );
  }

  deletePaymentData(payment_id: any): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        CLAIMS_API.CLAIMS_PAYMNET_MANAGEMENT +
        '/' +
        payment_id
    );
  }

  gatPaymentDetails(payment_id: any): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        CLAIMS_API.CLAIMS_PAYMNET_MANAGEMENT +
        '/' +
        payment_id
    );
  }

  getPayeeList(claim_id: number): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        CLAIMS_API.CLAIMS_PAYMENT_OVERVIEW +
        '/' +
        claim_id +
        '/payee'
    );
  }

  addPaymentDetails(addPaymentRequest: AddEditClaimPayment): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + CLAIMS_API.CLAIMS_PAYMNET_MANAGEMENT,
      addPaymentRequest
    );
  }

  getSparteByContract(
    contractId: number
  ): Observable<ContractSparteResponse> {
    const params = new HttpParams().append('contract_id', contractId);
    
    return this.http.get<ContractSparteResponse>(
      Api_URL.IC_API_URL + 
      Api.GET_SPARTE_BY_CONTRACT,
      { params }
    )
  }
}
