import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { SubSendingElement } from '../contact-manager.component';
import * as _ from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { ContactManagerSharedService } from '@core/services/contact-manager-shared.service';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';

// interface for send data of second step to api call of post cm data
export interface POSTCMDATA {
  contact_manager_id: string;
  subsidiary: string;
  subsidiary_id: string;
  division_id: string;
  processes_id: string;
  cm_area_id: string;
  sparte_id: string;
  editor_id: string;
  actor_id: string;
  actor_type_id: string;
  product_id: string;
  extern_id: string;
  creator_id: string;
  user_role_id: string;
  process: string;
  sparte: string;
  product: string;
  actor_type: string;
  cm_area: string;
  actor: string;
  from: string;
  to: string;
  extern: string;
  editor: string;
  email: string;
  telephone: string;
  role: USER_ROLES;
  is_sparte_group: boolean;
}

@Component({
  selector: 'app-new-editor',
  templateUrl: './new-editor.component.html',
  styleUrls: ['./new-editor.component.scss'],
})
export class NewEditorComponent extends BaseComponent implements OnInit {
  actorname;
  processId;
  sparte_Id;
  produktId;
  bereichId;
  vonId;
  bisId;
  externId;
  bearbeiterId;
  title = 'Confirm';
  division_list = [];
  divisid;
  selectedDivisionId;
  selectedActorTypeId;
  actorid;
  actors = [];
  companies = [];
  selectedLiztuVnKdnr;
  actorTypes = [];
  selectedActorId;
  actor_type_disable = true;
  actor_disable = true;
  divisionPreSelected = false;
  Actor_PreSelected = false;
  Actor_type_PreSelected = false;
  buttonCancelName = 'Cancel';
  buttonConfirmName = 'Ok';
  options = ['test', 'test1', 'test2'];
  telephone: boolean;
  email: boolean;
  bearbeiter: boolean;
  extern: boolean;
  bis: boolean;
  von: boolean;
  reichweite: boolean;
  bereich: boolean;
  product: boolean;
  sparte: boolean;
  vorgang: boolean;
  user_role: USER_ROLES;
  userDataValue: LoggedInUser;
  isICUser: boolean;
  isCustomerUser: boolean;
  vorgange_name: any;
  phone = new FormControl();
  sparteId: any;
  division_id: any;
  sparte_name: any;
  productList = [];
  editorList = [];
  externList = [];
  bereichList = [];
  extern_disable = false;
  editor_disable = false;
  product_id;
  extern_id = '';
  editor_id = '';
  cm_area_id;
  email_id: any;
  telephone_number: any;
  extern_editor_message: boolean;
  von_disable = false;
  bis_disable = false;
  von_validation: string;
  serchfind: boolean;
  validation_von_message: boolean;
  serchfindto: boolean;
  validation_bis_message: boolean;
  bis_validation: string;
  actor_id: any;
  actor_type_name: any;
  process: any;
  sparte_array: any;
  processes: any;
  product_name: any;
  model: any = {};
  userid: any;
  allDivision: any;
  division_name: any;
  actor_type_id: any;
  message: string;
  actor_name: any;
  subsidiary_name: any;
  subsidiary_id: any;
  mandant: boolean;
  actortype: boolean;
  actor: boolean;
  process_id: any;
  sparte_id: any;
  is_sparte_group: any;
  product_disabled: boolean;
  Product__PreSelected = false;
  selectedProduct: any;
  cmArea: any;
  isCustomerAdmin = false;

  constructor(
    public dialogRef: MatDialogRef<NewEditorComponent>,
    public validationService: ValidationService,
    private serviceCall: ApiCallsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public supported_languages: any,
    private readonly _userService: UserService,
    private readonly _contactManagerSharedService: ContactManagerSharedService
  ) {
    super();
    this.userDataValue = this._userService.getLoggedInUser();
    this.user_role = this._userService.getUserRole();
    this.isCustomerAdmin = this.user_role === USER_ROLES.CUSTOMER_ADMIN;
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
    // handling recieved data from 1st step so can call necessary apis and store the data for further useif any
    if (data) {
      if (data.fromWhich == 'add') {
        // checking if any data is available for second step when user comeback from 1st step
        if (this._contactManagerSharedService.processId) {
          data.process = this._contactManagerSharedService.processName;
          data.processes_id = this._contactManagerSharedService.processId;
        }
        if (this._contactManagerSharedService.sparteId) {
          data.sparte = this._contactManagerSharedService.sparteName;
          data.sparte_id = this._contactManagerSharedService.sparteId;
        }
        if (this._contactManagerSharedService.productId) {
          data.product = this._contactManagerSharedService.productName;
          data.product_id = this._contactManagerSharedService.productId;
        }

        const cmAreaId = this._contactManagerSharedService.cmAreaId;
        if (cmAreaId) {
          data.cm_area = this._contactManagerSharedService.cmAreaName;
          this.cm_area_id = cmAreaId;
          data.cm_area_id = cmAreaId;
          if (data.cm_area == 'Kunden') {
            this.von_disable = true;
            this.bis_disable = true;
          }
        }

        data.from = this._contactManagerSharedService.from;
        data.to = this._contactManagerSharedService.to;

        if (this._contactManagerSharedService.externId) {
          this.editor_disable = true;
          data.extern = this._contactManagerSharedService.externName;
          data.extern_id = this._contactManagerSharedService.externId;
        }
        if (this._contactManagerSharedService.editorId) {
          this.extern_disable = true;
          data.editor = this._contactManagerSharedService.editorName;
          data.editor_id = this._contactManagerSharedService.editorId;
        }

        data.email = this._contactManagerSharedService.email;
        data.telephone = this._contactManagerSharedService.telephone;

        if (data.sparte_id) {
          this.loadProduct(data.sparte_id, data.is_sparte_group);
        }
      }
      // calling product api if any sparte is preselected
      this.process = data.process;
      this.sparte_name = data.sparte;
      if (data.fromWhich == 'edit') {
        if (data.process !== '') {
          this.vorgang = false;
        }
        this.loadProduct(data.sparte_id, data.is_sparte_group);
        this.process = data.processes_id;
        this.product_name = data.product_id;
        this.sparte_name = data.sparte;
        this.cm_area_id = data.cm_area_id;
        this.cmArea = data.cm_area;
        this.editor_id = data.editor_id;
        this.extern_id = data.extern_id;
        if (data.cm_area == 'Kunden') {
          this.von_disable = true;
          this.bis_disable = true;
        }
        if (
          data.extern !== '' &&
          data.extern !== null &&
          data.extern !== undefined
        ) {
          this.editor_disable = true;
        }
        if (
          data.editor !== '' &&
          data.editor !== null &&
          data.editor !== undefined
        ) {
          this.extern_disable = true;
        }
      }
      this.title = data.title ? data.title : this.title;
      this.buttonCancelName = data.buttonCancelName
        ? data.buttonCancelName
        : this.buttonCancelName;
      this.buttonConfirmName = data.buttonConfirmName
        ? data.buttonConfirmName
        : this.buttonConfirmName;
    }
  }
  ngOnInit(): void {
    // calling prior needed apis
    this.loadEditor();
    this.loadExtern();
    this.loadBereich();
    this.loadSparte();
    this.loadProcesses();

    if (this.data.fromWhich == 'edit') {
      this.loadMandant();
      this.loadActorType();
      this.loadActor();
      this.loadSubsidiary();
    }
  }
  // loading divisions api
  loadMandant() {
    this.userid = this._userService.getUserId();
    this.telephone = !!this.userDataValue.user_profile.user_data.mobile_number;
    this.email = !!this.userDataValue.user_profile.user_data.email_id;
    this.serviceCall
      .getDivision(this.userid)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.allDivision = data.divisions;
      });
  }
  // saving division data
  saveMandant(value) {
    this.mandant = false;
    this.actortype = true;
    this.actor = true;
    this.actorTypes = [];
    this.actors = [];
    this.companies = [];
    this.data.actortypename = '';
    this.data.actortype = '';
    this.data.actorname = '';
    this.data.actor = '';
    this.data.subsidiaryname = '';
    this.data.subsidiary = '';

    this.editorList = [];
    this.externList = [];
    this.extern = true;
    this.bearbeiter = true;
    this.extern_disable = false;
    this.editor_disable = false;
    this.data.editor = '';
    this.data.editor_id = '';
    this.data.extern = '';
    this.extern_id = '';
    this.data.email = '';
    this.data.telephone = '';

    this.division_name = value.value;
    this.data.mandantname = this.division_name;
    for (let i = 0; i < this.allDivision.length; i++) {
      if (this.allDivision[i].division_name == this.division_name) {
        this.division_id = this.allDivision[i].division_id;
        this.data.mandant = this.division_id;
        this.loadActorType();
      }
    }
  }
  // loading actor type
  loadActorType() {
    this.serviceCall
      .getActorTyp(this.user_role)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.actorTypes = data.actor_types;
      });
  }
  // saving actor type
  saveActorType(value) {
    this.actortype = false;
    this.actor = true;
    this.actors = [];
    this.companies = [];
    this.data.actorname = '';
    this.data.actor = '';
    this.data.subsidiaryname = '';
    this.data.subsidiary = '';
    this.extern_disable = false;
    this.editor_disable = false;
    this.editorList = [];
    this.externList = [];
    this.extern = true;
    this.bearbeiter = true;
    this.data.editor = '';
    this.data.editor_id = '';
    this.data.extern = '';
    this.extern_id = '';
    this.data.email = '';
    this.data.telephone = '';

    this.actor_type_name = value.value;
    this.data.actortypename = this.actor_type_name;
    for (let i = 0; i < this.actorTypes.length; i++) {
      if (this.actorTypes[i].actor_type_name == this.actor_type_name) {
        this.actor_type_id = this.actorTypes[i].actorTypeId;
        this.data.actortype = this.actor_type_id;
        this.loadActor();
      }
    }
  }
  // loading actor
  loadActor() {
    this.serviceCall
      .getActor(
        this.data.actortypename,
        this.data.mandant,
        this.user_role,
        this.userid
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.actors.length > 0) {
          this.message = '';
          this.actors = data.actors;
        } else {
          this.actors = [];
          this.message = 'KeinAkteur_info';
        }
      });
  }
  // saving actor data
  saveActor(value) {
    this.actor = false;
    this.companies = [];
    this.data.subsidiaryname = '';
    this.data.subsidiary = '';
    this.actor_name = value.value;
    this.editorList = [];
    this.externList = [];
    this.extern = true;
    this.bearbeiter = true;
    this.data.editor = '';
    this.data.editor_id = '';
    this.data.extern = '';
    this.extern_id = '';
    this.data.email = '';
    this.data.telephone = '';
    this.extern_disable = false;
    this.editor_disable = false;

    this.data.actorname = this.actor_name;
    for (let i = 0; i < this.actors.length; i++) {
      if (this.actors[i].actor_name == this.actor_name) {
        this.actor_id = this.actors[i].actor_id;
        this.data.actor = this.actor_id;
        this.loadSubsidiary();
        this.loadEditor();
        this.loadExtern();
      }
    }
  }
  // loading subsidiary
  loadSubsidiary() {
    const subsidiarySendingData: SubSendingElement = {
      divisionId: this.data.mandant,
      actor_type: this.data.actortypename,
      liztu_vn_kdnr: this.data.actor,
      role: this.user_role,
    };
    this.serviceCall
      .getSubsidiary(subsidiarySendingData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.companies = data.customers;
      });
  }
  // saving subsidiary
  saveSubsidiary(value) {
    this.subsidiary_name = value.value;
    this.data.subsidiaryname = this.subsidiary_name;
    for (let i = 0; i < this.companies.length; i++) {
      if (this.companies[i].company_name == this.subsidiary_name) {
        this.subsidiary_id = this.companies[i].liztu_vn_kdnr;
        this.data.subsidiary = this.subsidiary_id;
      }
    }
  }
  // loading cm area
  loadBereich() {
    this.serviceCall
      .getBereich()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.bereichList = data.bereich;
      });
  }
  // loading extern
  loadExtern() {
    this.serviceCall
      .getExtern()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.externList = data.extern;
      });
  }
  // loading sparte
  loadSparte() {
    this.product_disabled = false;
    // previously we were sending parent id and its value was null so sending null here as customer_id.
    this.serviceCall
      .getSparte(
        this.userDataValue.user_profile.customer_details.division_id,
        null,
        this.user_role
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.sparte_array = data.sparte;
        for (let i = 0; i < this.sparte_array.length; i++) {
          if (this.data.fromWhich == 'edit') {
            if (this.sparte_array[i].fixer_code == this.data.sparte_id) {
              this.data.sparte = this.sparte_array[i].description;
              this.sparte_name = this.data.sparte;
            }
          }
          if (this.sparte_array[i].description == this.sparte_name) {
            this.sparteId = this.sparte_array[i].fixer_code;
            this.is_sparte_group = this.sparte_array[i].is_sparte_group;
            if (this.is_sparte_group == false) {
              this.loadProduct(this.sparteId, this.is_sparte_group);
            } else {
              this.product_disabled = true;
            }
          }
        }
      });
  }
  // load process
  loadProcesses() {
    this.serviceCall
      .getProcess()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.processes = data.processes;
      });
  }
  // load editor
  loadEditor() {
    this.serviceCall
      .getEditor(
        this.data.mandant,
        this.data.actor,
        this.data.actortypename,
        this.user_role
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.editorList = data.berbeiters;
        if (this._contactManagerSharedService.editorId) {
          this.data.editor = this._contactManagerSharedService.editorName;
          this.data.editor_id = this._contactManagerSharedService.editorId;
          for (let j = 0; j < this.editorList.length; j++) {
            if (
              this.editorList[j].editor_id.indexOf(this.data.editor_id) == -1
            ) {
              this.data.editor = '';
              this.data.editor_id = '';
              this.data.telephone = '';
              this.data.email = '';
              this.extern_disable = false;
              this.editor_disable = false;
            } else {
              this.extern_disable = true;
            }
          }
        }
      });
  }
  // load product
  loadProduct(sparte, is_sparte_group) {
    if (_.isNil(sparte) || _.isNil(is_sparte_group)) {
      return;
    }
    this.serviceCall
      .getProduct(sparte, is_sparte_group)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.productList = data.products;
        if (data.products.length == 1) {
          this.Product__PreSelected = true;
          this.selectedProduct = data.products[0].productName;
          this.product = false;
          this.product_id = data.products[0].productId;
          this._contactManagerSharedService.productId = this.product_id;
          this.data.product_id = this.product_id;
          this.data.product = data.products[0].productName;
          this.product_name = this.selectedProduct;
        }
      });
  }
  // save process which user selected
  // giving validation variable false value which shows the field is blank or not
  // save data so if user go back to 1st step the selected data will remain persist
  typeVorgange(item) {
    this.vorgang = false;
    this.process = item.value;
    this._contactManagerSharedService.processName = item.value;
    for (let i = 0; i < this.processes.length; i++) {
      if (this.processes[i].operation_name == item.value) {
        this.process_id = this.processes[i].processes_id;
        this._contactManagerSharedService.processId = this.process_id;
        this.data.processes_id = this.process_id;
      }
    }
  }
  // save sparte which user selected
  // giving validation variable false value which shows the field is blank or not
  // save data so if user go back to 1st step the selected data will remain persist
  typeSparte(item) {
    this._contactManagerSharedService.sparteName = item.value;
    this._contactManagerSharedService.productId = null;
    this._contactManagerSharedService.productName = null;
    this.data.product_id = '';
    this.data.product = '';
    this.productList = [];
    this.product_disabled = false;
    for (let i = 0; i < this.sparte_array.length; i++) {
      if (this.sparte_array[i].description == item.value) {
        this.is_sparte_group = this.sparte_array[i].is_sparte_group;
        if (this.is_sparte_group == true) {
          this.sparte_id = this.sparte_array[i].sparte_id;
        } else {
          this.sparte_id = this.sparte_array[i].fixer_code;
        }
        this._contactManagerSharedService.sparteId = this.sparte_id;
        this.data.sparte_id = this.sparte_id;
        // load product if the is_sparte_group is false
        if (this.is_sparte_group == false) {
          this.loadProduct(this.sparte_id, this.is_sparte_group);
          this.product = true;
        } else {
          this.product = false;
          this.product_disabled = true;
        }
      }
    }
    this.sparte = false;
    this.data.product_id = '';
    this.product_name = '';
    this.sparte_name = item.value;
  }
  // save product which user selected
  // giving validation variable false value which shows the field is blank or not
  // save data so if user go back to 1st step the selected data will remain persist
  typeProduct(product) {
    this.product = false;
    this._contactManagerSharedService.productName = product.source.triggerValue;
    for (let i = 0; i <= this.processes.length; i++) {
      if (this.productList[i].productName == product.value) {
        this.product_id = this.productList[i].productId;
        this._contactManagerSharedService.productId = this.product_id;
        this.data.product_id = this.product_id;
      }
    }
    this.product_name = product.value;
  }
  // save area which user selected
  // giving validation variable false value which shows the field is blank or not
  // save data so if user go back to 1st step the selected data will remain persist
  typeBereich(area) {
    this._contactManagerSharedService.cmAreaName = area.value;
    this.bereich = false;
    if (area.value == 'Kunden') {
      this.von = false;
      this.von_validation = '';
      this.serchfind = true;
      this.data.from = '';
      this.bis = false;
      this.bis_validation = '';
      this.serchfindto = true;
      this.data.to = '';
      this.von_disable = true;
      this.bis_disable = true;
    } else {
      this.cmArea = area.value;
      this.von_disable = false;
      this.data.from = '';
      this.von = true;
      this.data.to = '';
      this.bis = true;
      this.bis_disable = false;
    }
    for (let i = 0; i < this.bereichList.length; i++) {
      if (this.bereichList[i].cmArea == area.value) {
        this.cm_area_id = this.bereichList[i].contactManagerAreaId;
        this.data.cm_area_id = this.cm_area_id;
        this._contactManagerSharedService.cmAreaId = this.cm_area_id;
      }
    }
  }
  // save to which user selected
  // giving validation variable false value which shows the field is blank or not
  typeVon(item) {
    this._contactManagerSharedService.from = item;
    this.von = false;
    if (item == '') {
      this.von = true;
      if (this.cmArea == 'Kundenendnummer') {
        this.von_validation = 'von_validation_label_Kundenendnummer';
      } else if (this.cmArea == 'Kundennummer') {
        this.von_validation = 'von_validation_label_Kundennummer';
      } else if (this.cmArea == 'Nachname') {
        this.von_validation = 'von_validation_label_Nachname';
      } else if (this.cmArea == 'Firmenname') {
        this.von_validation = 'von_validation_label_Firmenname';
      } else if (this.cmArea == 'Postleitzahl') {
        this.von_validation = 'von_validation_label_Postleitzahl';
      } else {
        this.von_validation = 'von_validation_label';
      }
    }
    if (this.cmArea == 'Kundenendnummer') {
      const regexp = new RegExp('^[0-9]{2}$');
      this.serchfind = regexp.test(item);
    } else if (this.cmArea == 'Kundennummer') {
      const regexp = new RegExp('^[0-9]{1,6}$');
      this.serchfind = regexp.test(item);
    } else if (this.cmArea == 'Nachname' || this.cmArea == 'Firmenname') {
      const regexp = new RegExp('^[a-zA-Z]{1}$');
      this.serchfind = regexp.test(item);
    } else if (this.cmArea == 'Postleitzahl') {
      const regexp = new RegExp('^[0-9]{3}$');
      this.serchfind = regexp.test(item);
    } else {
      const regexp = new RegExp('^[a-zA-Z0-9]{3}$');
      this.serchfind = regexp.test(item);
    }
    if (this.serchfind == true) {
      this.von = false;
    } else {
      this.von = true;
      if (this.cmArea == 'Kundenendnummer') {
        this.von_validation = 'von_validation_label_Kundenendnummer';
      } else if (this.cmArea == 'Kundennummer') {
        this.von_validation = 'von_validation_label_Kundennummer';
      } else if (this.cmArea == 'Nachname') {
        this.von_validation = 'von_validation_label_Nachname';
      } else if (this.cmArea == 'Firmenname') {
        this.von_validation = 'von_validation_label_Firmenname';
      } else if (this.cmArea == 'Postleitzahl') {
        this.von_validation = 'von_validation_label_Postleitzahl';
      } else {
        this.von_validation = 'von_validation_label';
      }
    }
  }
  // save from which user selected
  // giving validation variable false value which shows the field is blank or not
  typeBis(item) {
    this._contactManagerSharedService.to = item;
    this.bis = false;
    if (item == '') {
      this.bis = true;
      if (this.cmArea == 'Kundenendnummer') {
        this.bis_validation = 'von_validation_label_Kundenendnummer';
      } else if (this.cmArea == 'Kundennummer') {
        this.bis_validation = 'von_validation_label_Kundennummer';
      } else if (this.cmArea == 'Nachname') {
        this.bis_validation = 'von_validation_label_Nachname';
      } else if (this.cmArea == 'Firmenname') {
        this.bis_validation = 'von_validation_label_Firmenname';
      } else if (this.cmArea == 'Postleitzahl') {
        this.bis_validation = 'von_validation_label_Postleitzahl';
      } else {
        this.bis_validation = 'von_validation_label';
      }
    }
    if (this.cmArea == 'Kundenendnummer') {
      const regexp = new RegExp('^[0-9]{2}$');
      this.serchfindto = regexp.test(item);
    } else if (this.cmArea == 'Kundennummer') {
      const regexp = new RegExp('^[0-9]{1,6}$');
      this.serchfindto = regexp.test(item);
    } else if (this.cmArea == 'Nachname' || this.cmArea == 'Firmenname') {
      const regexp = new RegExp('^[a-zA-Z]{1}$');
      this.serchfindto = regexp.test(item);
    } else if (this.cmArea == 'Postleitzahl') {
      const regexp = new RegExp('^[0-9]{3}$');
      this.serchfindto = regexp.test(item);
    } else {
      const regexp = new RegExp('^[a-zA-Z0-9]{3}$');
      this.serchfindto = regexp.test(item);
    }
    if (this.serchfindto == true) {
      this.bis = false;
    } else {
      this.bis = true;
      if (this.cmArea == 'Kundenendnummer') {
        this.bis_validation = 'von_validation_label_Kundenendnummer';
      } else if (this.cmArea == 'Kundennummer') {
        this.bis_validation = 'von_validation_label_Kundennummer';
      } else if (this.cmArea == 'Nachname') {
        this.bis_validation = 'von_validation_label_Nachname';
      } else if (this.cmArea == 'Firmenname') {
        this.bis_validation = 'von_validation_label_Firmenname';
      } else if (this.cmArea == 'Postleitzahl') {
        this.bis_validation = 'von_validation_label_Postleitzahl';
      } else {
        this.bis_validation = 'von_validation_label';
      }
    }
  }
  // save extern which user selected
  // giving validation variable false value which shows the field is blank or not
  // save data so if user go back to 1st step the selected data will remain persist
  typeExtern(extern) {
    if (extern.value !== 'reset') {
      this._contactManagerSharedService.externName = extern.value;
      this.extern_editor_message = false;
      this.extern = false;
      if (this.isICUser) {
        this.bearbeiter = false;
        this.editor_disable = true;
      }
      for (let i = 0; i < this.externList.length; i++) {
        if (this.externList[i].userRoleName == extern.value) {
          this.extern_id = this.externList[i].userRoleId;
          this._contactManagerSharedService.externId = this.extern_id;
        }
      }
      this.extern = false;
    }
  }
  reserExtern() {
    this.extern_editor_message = true;
    this.bearbeiter = true;
    this.extern = true;
    this.extern_disable = false;
    this.editor_disable = false;
    this.extern_id = '';
    this.data.extern = '';
    this.data.extern_id = '';
    this._contactManagerSharedService.externId = null;
  }
  // save editor which user selected
  // giving validation variable false value which shows the field is blank or not
  // save data so if user go back to 1st step the selected data will remain persist
  typeBearbeiter(editor) {
    if (editor.value !== 'reset') {
      this.extern_editor_message = false;
      this._contactManagerSharedService.editorName = editor.value;
      this.bearbeiter = false;
      if (this.isICUser) {
        this.extern = false;
        this.extern_disable = true;
      }
      for (let i = 0; i < this.editorList.length; i++) {
        if (this.editorList[i].sortedName == editor.value) {
          this.editor_id = this.editorList[i].editor_id;
          this._contactManagerSharedService.editorId = this.editor_id;
          this.email_id = this.editorList[i].email_id;
          this.telephone_number = this.editorList[i].mobile_number;
          if (this.telephone_number == null) {
            this.data.telephone = '';
          } else {
            this.data.telephone = this.telephone_number;
            this._contactManagerSharedService.telephone = this.telephone_number;
          }
          if (this.email_id == null) {
            this.data.email = '';
          } else {
            this.data.email = this.email_id;
            this._contactManagerSharedService.email = this.email_id;
          }
        }
      }
    }
  }
  reserBearbeiter() {
    this.extern_editor_message = true;
    this.bearbeiter = true;
    this.extern = true;
    this.extern_disable = false;
    this.editor_disable = false;
    this.editor_id = '';
    this.extern_id = '';
    this.data.telephone = '';
    this.data.email = '';
    this.data.editor = '';
    this.data.editor_id = '';
    this._contactManagerSharedService.email = null;
    this._contactManagerSharedService.telephone = null;
    this._contactManagerSharedService.editorId = null;
  }
  // save email
  typeEmail() {
    this.email = false;
  }
  // save telefon
  typeTelefon() {
    this.telephone = false;
  }
  // discard step 2
  onNoClick() {
    if (this.data.fromWhich == 'edit') {
      this._contactManagerSharedService.discardAllValues();
    }
    this.dialogRef.close();
  }
  // sending data to post cm api and cloasing step 2 and 1
  save(data) {
    for (let i = 0; i < this.sparte_array.length; i++) {
      if (this.sparte_array[i].fixer_code == this.data.sparte_id) {
        this.is_sparte_group = this.sparte_array[i].is_sparte_group;
      }
    }
    if (data.product_id == null) {
      data.product_id = '';
    }

    // if is_sparte_group is true then product data will be send in post sm data else the else condition will execute
    if (data.product_id == '' && this.is_sparte_group == true) {
      // checking if everything is filled
      if (
        data.processes_id !== '' &&
        data.sparte_id !== '' &&
        data.mandant !== '' &&
        data.actor !== '' &&
        data.actortype !== ''
      ) {
        // checking if nothing is undefined
        if (
          data.processes_id !== undefined &&
          data.sparte_id !== undefined &&
          data.mandant !== undefined &&
          data.actor !== undefined &&
          data.actortype !== undefined
        ) {
          // checking the cm area validation if value in kunden then von and bis will not send and remain blank else the else condition execte
          if (data.cm_area == 'Kunden') {
            this.von = false;
            this.data.from = '';
            this.bis = false;
            this.data.to = '';
            this.von_disable = true;
            this.bis_disable = true;
          } else {
            this.von = true;
            this.bis = true;
            this.von_disable = false;
            this.bis_disable = false;
          }
          this.product = false;
          // user role check if user role is customer-admin the area will remain blank else the else will extecute
          if (
            this.user_role === USER_ROLES.CUSTOMER_ADMIN &&
            data.cm_area == ''
          ) {
            this.bereich = false;
          } else if (
            this.user_role !== USER_ROLES.CUSTOMER_ADMIN &&
            data.cm_area == ''
          ) {
            this.bereich = true;
          } else {
            this.bereich = false;
          }
          // after all the checks doing all the validation variable false so we can close the popup without error
          this.vorgang = false;
          this.sparte = false;
          this.von = false;
          this.bis = false;
          this.email = false;
          this.telephone = false;
          this.extern = false;
          this.bearbeiter = false;
          this.actortype = false;
          this.actor = false;
          if (data.fromWhich == 'add') {
            data.cm_area_id = this.cm_area_id;
            data.editor_id = this.editor_id;
            data.extern_id = this.extern_id;
          }

          if (data.to != '' && this.serchfindto == true) {
            this.bis = false;
          } else if (data.to != '' && this.serchfindto == false) {
            this.bis = true;
          }

          if (data.from != '' && this.serchfind == true) {
            this.von = false;
          } else if (data.from != '' && this.serchfind == false) {
            this.von = true;
          }

          if (data.from == '' && this.von_disable == false) {
            this.von = true;
          } else if (data.from == '' && this.von_disable == true) {
            this.von = false;
          }

          if (data.to == '' && this.bis_disable == false) {
            this.bis = true;
          } else if (data.to == '' && this.bis_disable == true) {
            this.bis = false;
          }

          if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
            this.von = false;
            this.bis = false;
            this.bereich = false;
          }

          if (this.isICUser) {
            if (data.extern == '' && data.editor == '') {
              this.extern_editor_message = true;
              this.extern = true;
              this.bearbeiter = true;
            } else if (data.extern != '' && data.editor == '') {
              this.extern_editor_message = false;
              this.editor_id = '';
              if (
                this.product == false &&
                this.bereich == false &&
                this.vorgang == false &&
                this.sparte == false &&
                this.von == false &&
                this.bis == false &&
                this.email == false &&
                this.telephone == false &&
                this.extern == false &&
                this.bearbeiter == false &&
                this.actortype == false &&
                this.actor == false
              ) {
                this._contactManagerSharedService.discardAllValues();
                const postData: POSTCMDATA = {
                  contact_manager_id: data.contact_manager_id,
                  subsidiary: data.subsidiaryname,
                  subsidiary_id: data.subsidiary,
                  division_id: data.mandant,
                  processes_id: data.processes_id,
                  cm_area_id: this.cm_area_id,
                  sparte_id: data.sparte_id,
                  editor_id: this.editor_id,
                  actor_id: data.actor,
                  actor_type_id: data.actortype,
                  product_id: data.product_id,
                  extern_id: this.extern_id,
                  creator_id: this.userid,
                  user_role_id: this.extern_id,
                  process: data.process,
                  sparte: data.sparte,
                  product: data.product,
                  actor_type: data.actortypename,
                  cm_area: data.cm_area,
                  actor: data.actorname,
                  from: data.from,
                  to: data.to,
                  extern: data.extern,
                  editor: data.editor,
                  email: data.email,
                  telephone: data.telephone,
                  role: this.user_role,
                  is_sparte_group: this.is_sparte_group,
                };
                this.dialogRef.close(postData);
              }
            }
            // if extern and editor both are blank showing an error
            else if (data.extern == '' && data.editor != '') {
              this.extern_id = '';
              this.extern_editor_message = false;
              if (
                this.product == false &&
                this.bereich == false &&
                this.vorgang == false &&
                this.sparte == false &&
                this.von == false &&
                this.bis == false &&
                this.email == false &&
                this.telephone == false &&
                this.extern == false &&
                this.bearbeiter == false &&
                this.actortype == false &&
                this.actor == false
              ) {
                this._contactManagerSharedService.discardAllValues();
                const postData: POSTCMDATA = {
                  contact_manager_id: data.contact_manager_id,
                  subsidiary: data.subsidiaryname,
                  subsidiary_id: data.subsidiary,
                  division_id: data.mandant,
                  processes_id: data.processes_id,
                  cm_area_id: this.cm_area_id,
                  sparte_id: data.sparte_id,
                  editor_id: this.editor_id,
                  actor_id: data.actor,
                  actor_type_id: data.actortype,
                  product_id: data.product_id,
                  extern_id: this.extern_id,
                  creator_id: this.userid,
                  user_role_id: this.extern_id,
                  process: data.process,
                  sparte: data.sparte,
                  product: data.product,
                  actor_type: data.actortypename,
                  cm_area: data.cm_area,
                  actor: data.actorname,
                  from: data.from,
                  to: data.to,
                  extern: data.extern,
                  editor: data.editor,
                  email: data.email,
                  telephone: data.telephone,
                  role: this.user_role,
                  is_sparte_group: this.is_sparte_group,
                };

                this.dialogRef.close(postData);
              }
            }
          }
          // if user is a customer user the editor will remain blank
          else if (this.isCustomerUser) {
            if (data.editor == '') {
              this.bearbeiter = true;
            } else {
              if (
                this.product == false &&
                this.bereich == false &&
                this.vorgang == false &&
                this.sparte == false &&
                this.von == false &&
                this.bis == false &&
                this.email == false &&
                this.telephone == false &&
                this.extern == false &&
                this.bearbeiter == false &&
                this.actortype == false &&
                this.actor == false
              ) {
                this._contactManagerSharedService.discardValuesExceptCmArea();
                const postData: POSTCMDATA = {
                  contact_manager_id: data.contact_manager_id,
                  subsidiary: data.subsidiaryname,
                  subsidiary_id: data.subsidiary,
                  division_id: data.mandant,
                  processes_id: data.processes_id,
                  cm_area_id: this.cm_area_id,
                  sparte_id: data.sparte_id,
                  editor_id: this.editor_id,
                  actor_id: data.actor,
                  actor_type_id: data.actortype,
                  product_id: data.product_id,
                  extern_id: this.extern_id,
                  creator_id: this.userid,
                  user_role_id: this.extern_id,
                  process: data.process,
                  sparte: data.sparte,
                  product: data.product,
                  actor_type: data.actortypename,
                  cm_area: data.cm_area,
                  actor: data.actorname,
                  from: data.from,
                  to: data.to,
                  extern: data.extern,
                  editor: data.editor,
                  email: data.email,
                  telephone: data.telephone,
                  role: this.user_role,
                  is_sparte_group: this.is_sparte_group,
                };

                this.dialogRef.close(postData);
              }
            }
          }
        } else {
          if (data.processes_id == '') {
            this.vorgang = true;
          }
          if (data.processes_id == undefined) {
            this.vorgang = true;
          }
          if (data.sparte_id == '') {
            this.sparte = true;
          }
          if (data.sparte_id == undefined) {
            this.sparte = true;
          }
          if (
            data.product == '' ||
            data.product == undefined ||
            data.product == null
          ) {
            this.product = true;
          }
          if (data.cm_area == '') {
            this.bereich = true;
          }
          if (data.from == '') {
            if (this.von_disable == true) {
              this.von = false;
            } else if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
              this.von = false;
            } else {
              this.von = true;
            }
          } else if (data.from != '' && this.serchfind == false) {
            this.validation_von_message = true;
            this.von = true;
          }
          if (data.to == '') {
            if (this.bis_disable == true) {
              this.bis = false;
            } else if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
              this.bis = false;
            } else {
              this.bis = true;
            }
          } else if (data.to != '' && this.serchfindto == false) {
            this.validation_bis_message = true;
            this.bis = true;
          }
          if (this.isICUser) {
            if (data.extern == '' && data.editor == '') {
              this.extern_editor_message = true;
              this.extern = true;
              this.bearbeiter = true;
            } else if (
              (data.extern !== '' && data.editor == '') ||
              (data.extern == '' && data.editor !== '')
            ) {
              this.extern_editor_message = false;
              this.extern = false;
              this.bearbeiter = false;
            }
          } else if (this.isCustomerUser) {
            if (data.editor == '') {
              this.bearbeiter = true;
            }
          }
          if (data.mandant == '') {
            this.mandant = true;
          }
          if (data.mandant == undefined) {
            this.mandant = true;
          }
          if (data.actor == '') {
            this.actor = true;
          }
          if (data.actor == undefined) {
            this.actor = true;
          }
          if (data.actortype == '') {
            this.actortype = true;
          }
          if (data.actortype == undefined) {
            this.actortype = true;
          }
        }
      } else {
        if (data.processes_id == '') {
          this.vorgang = true;
        }
        if (data.processes_id == undefined) {
          this.vorgang = true;
        }
        if (data.sparte_id == '') {
          this.sparte = true;
        }
        if (data.sparte_id == undefined) {
          this.sparte = true;
        }
        if (data.product == '') {
          this.product = true;
        }
        if (data.cm_area == '') {
          this.bereich = true;
        }
        if (data.mandant == '') {
          this.mandant = true;
        }
        if (data.mandant == undefined) {
          this.mandant = true;
        }
        if (data.actor == '') {
          this.actor = true;
        }
        if (data.actor == undefined) {
          this.actor = true;
        }
        if (data.actortype == '') {
          this.actortype = true;
        }
        if (data.actortype == undefined) {
          this.actortype = true;
        }
        if (data.from == '') {
          if (this.von_disable == true) {
            this.von = false;
          } else if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
            this.von = false;
          } else {
            this.von = true;
          }
        } else if (data.from != '' && this.serchfind == false) {
          this.validation_von_message = true;
          this.von = true;
        }
        if (data.to == '') {
          if (this.bis_disable == true) {
            this.bis = false;
          } else if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
            this.bis = false;
          } else {
            this.bis = true;
          }
        } else if (data.to != '' && this.serchfindto == false) {
          this.validation_bis_message = true;
          this.bis = true;
        }
        if (this.isICUser) {
          if (data.extern == '' && data.editor == '') {
            this.extern_editor_message = true;
            this.extern = true;
            this.bearbeiter = true;
          } else if (
            (data.extern !== '' && data.editor == '') ||
            (data.extern == '' && data.editor !== '')
          ) {
            this.extern_editor_message = false;
            this.extern = false;
            this.bearbeiter = false;
          }
        } else if (this.isCustomerUser) {
          if (data.editor == '') {
            this.bearbeiter = true;
          }
        }
      }
    } else if (data.product_id !== '' && this.is_sparte_group == false) {
      if (
        data.processes_id !== '' &&
        data.sparte_id !== '' &&
        data.product_id !== '' &&
        data.mandant !== '' &&
        data.actor !== '' &&
        data.actortype !== ''
      ) {
        if (
          data.processes_id !== undefined &&
          data.sparte_id !== undefined &&
          data.product_id !== undefined &&
          data.mandant !== undefined &&
          data.actor !== undefined &&
          data.actortype !== undefined
        ) {
          if (data.cm_area == 'Kunden') {
            this.von = false;
            this.data.from = '';
            this.bis = false;
            this.data.to = '';
            this.von_disable = true;
            this.bis_disable = true;
          } else {
            this.von = true;
            this.bis = true;
            this.von_disable = false;
            this.bis_disable = false;
          }
          this.product = false;

          if (
            this.user_role === USER_ROLES.CUSTOMER_ADMIN &&
            data.cm_area == ''
          ) {
            this.bereich = false;
          } else if (
            this.user_role !== USER_ROLES.CUSTOMER_ADMIN &&
            data.cm_area == ''
          ) {
            this.bereich = true;
          } else {
            this.bereich = false;
          }

          this.vorgang = false;
          this.sparte = false;
          this.von = false;
          this.bis = false;
          this.email = false;
          this.telephone = false;
          this.extern = false;
          this.bearbeiter = false;
          this.actortype = false;
          this.actor = false;
          if (data.fromWhich == 'add') {
            data.cm_area_id = this.cm_area_id;
            data.editor_id = this.editor_id;
            data.extern_id = this.extern_id;
          }

          if (data.to != '' && this.serchfindto == true) {
            this.bis = false;
          } else if (data.to != '' && this.serchfindto == false) {
            this.bis = true;
          }

          if (data.from != '' && this.serchfind == true) {
            this.von = false;
          } else if (data.from != '' && this.serchfind == false) {
            this.von = true;
          }

          if (data.from == '' && this.von_disable == false) {
            this.von = true;
          } else if (data.from == '' && this.von_disable == true) {
            this.von = false;
          }

          if (data.to == '' && this.bis_disable == false) {
            this.bis = true;
          } else if (data.to == '' && this.bis_disable == true) {
            this.bis = false;
          }

          if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
            this.von = false;
            this.bis = false;
            this.bereich = false;
          }

          if (this.isICUser) {
            if (data.extern == '' && data.editor == '') {
              this.extern_editor_message = true;
              this.extern = true;
              this.bearbeiter = true;
            } else if (data.extern != '' && data.editor == '') {
              this.editor_id = '';
              this.extern_editor_message = false;
              if (
                this.product == false &&
                this.bereich == false &&
                this.vorgang == false &&
                this.sparte == false &&
                this.von == false &&
                this.bis == false &&
                this.email == false &&
                this.telephone == false &&
                this.extern == false &&
                this.bearbeiter == false &&
                this.actortype == false &&
                this.actor == false
              ) {
                this._contactManagerSharedService.discardAllValues();
                const postData: POSTCMDATA = {
                  contact_manager_id: data.contact_manager_id,
                  subsidiary: data.subsidiaryname,
                  subsidiary_id: data.subsidiary,
                  division_id: data.mandant,
                  processes_id: data.processes_id,
                  cm_area_id: this.cm_area_id,
                  sparte_id: data.sparte_id,
                  editor_id: this.editor_id,
                  actor_id: data.actor,
                  actor_type_id: data.actortype,
                  product_id: data.product_id,
                  extern_id: this.extern_id,
                  creator_id: this.userid,
                  user_role_id: this.extern_id,
                  process: data.process,
                  sparte: data.sparte,
                  product: data.product,
                  actor_type: data.actortypename,
                  cm_area: data.cm_area,
                  actor: data.actorname,
                  from: data.from,
                  to: data.to,
                  extern: data.extern,
                  editor: data.editor,
                  email: data.email,
                  telephone: data.telephone,
                  role: this.user_role,
                  is_sparte_group: this.is_sparte_group,
                };
                this.dialogRef.close(postData);
              }
            } else if (data.extern == '' && data.editor != '') {
              this.extern_id = '';
              this.extern_editor_message = false;
              if (
                this.product == false &&
                this.bereich == false &&
                this.vorgang == false &&
                this.sparte == false &&
                this.von == false &&
                this.bis == false &&
                this.email == false &&
                this.telephone == false &&
                this.extern == false &&
                this.bearbeiter == false &&
                this.actortype == false &&
                this.actor == false
              ) {
                this._contactManagerSharedService.discardAllValues();
                const postData: POSTCMDATA = {
                  contact_manager_id: data.contact_manager_id,
                  subsidiary: data.subsidiaryname,
                  subsidiary_id: data.subsidiary,
                  division_id: data.mandant,
                  processes_id: data.processes_id,
                  cm_area_id: this.cm_area_id,
                  sparte_id: data.sparte_id,
                  editor_id: this.editor_id,
                  actor_id: data.actor,
                  actor_type_id: data.actortype,
                  product_id: data.product_id,
                  extern_id: this.extern_id,
                  creator_id: this.userid,
                  user_role_id: this.extern_id,
                  process: data.process,
                  sparte: data.sparte,
                  product: data.product,
                  actor_type: data.actortypename,
                  cm_area: data.cm_area,
                  actor: data.actorname,
                  from: data.from,
                  to: data.to,
                  extern: data.extern,
                  editor: data.editor,
                  email: data.email,
                  telephone: data.telephone,
                  role: this.user_role,
                  is_sparte_group: this.is_sparte_group,
                };

                this.dialogRef.close(postData);
              }
            }
          } else if (this.isCustomerUser) {
            if (data.editor == '') {
              this.bearbeiter = true;
            } else {
              if (
                this.product == false &&
                this.bereich == false &&
                this.vorgang == false &&
                this.sparte == false &&
                this.von == false &&
                this.bis == false &&
                this.email == false &&
                this.telephone == false &&
                this.extern == false &&
                this.bearbeiter == false &&
                this.actortype == false &&
                this.actor == false
              ) {
                this._contactManagerSharedService.discardValuesExceptCmArea();
                const postData: POSTCMDATA = {
                  contact_manager_id: data.contact_manager_id,
                  subsidiary: data.subsidiaryname,
                  subsidiary_id: data.subsidiary,
                  division_id: data.mandant,
                  processes_id: data.processes_id,
                  cm_area_id: this.cm_area_id,
                  sparte_id: data.sparte_id,
                  editor_id: this.editor_id,
                  actor_id: data.actor,
                  actor_type_id: data.actortype,
                  product_id: data.product_id,
                  extern_id: this.extern_id,
                  creator_id: this.userid,
                  user_role_id: this.extern_id,
                  process: data.process,
                  sparte: data.sparte,
                  product: data.product,
                  actor_type: data.actortypename,
                  cm_area: data.cm_area,
                  actor: data.actorname,
                  from: data.from,
                  to: data.to,
                  extern: data.extern,
                  editor: data.editor,
                  email: data.email,
                  telephone: data.telephone,
                  role: this.user_role,
                  is_sparte_group: this.is_sparte_group,
                };
                this.dialogRef.close(postData);
              }
            }
          }
        } else {
          if (data.processes_id == '') {
            this.vorgang = true;
          }
          if (data.processes_id == undefined) {
            this.vorgang = true;
          }
          if (data.sparte_id == '') {
            this.sparte = true;
          }
          if (data.sparte_id == undefined) {
            this.sparte = true;
          }
          if (
            data.product == '' ||
            data.product == undefined ||
            data.product == null
          ) {
            this.product = true;
          }
          if (data.cm_area == '') {
            this.bereich = true;
          }
          if (data.from == '') {
            if (this.von_disable == true) {
              this.von = false;
            } else if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
              this.von = false;
            } else {
              this.von = true;
            }
          } else if (data.from != '' && this.serchfind == false) {
            this.validation_von_message = true;
            this.von = true;
          }
          if (data.to == '') {
            if (this.bis_disable == true) {
              this.bis = false;
            } else if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
              this.bis = false;
            } else {
              this.bis = true;
            }
          } else if (data.to != '' && this.serchfindto == false) {
            this.validation_bis_message = true;
            this.bis = true;
          }
          if (this.isICUser) {
            if (data.extern == '' && data.editor == '') {
              this.extern_editor_message = true;
              this.extern = true;
              this.bearbeiter = true;
            } else if (
              (data.extern !== '' && data.editor == '') ||
              (data.extern == '' && data.editor !== '')
            ) {
              this.extern_editor_message = false;
              this.extern = false;
              this.bearbeiter = false;
            }
          } else if (this.isCustomerUser) {
            if (data.editor == '') {
              this.bearbeiter = true;
            }
          }
          if (data.mandant == '') {
            this.mandant = true;
          }
          if (data.mandant == undefined) {
            this.mandant = true;
          }
          if (data.actor == '') {
            this.actor = true;
          }
          if (data.actor == undefined) {
            this.actor = true;
          }
          if (data.actortype == '') {
            this.actortype = true;
          }
          if (data.actortype == undefined) {
            this.actortype = true;
          }
        }
      } else {
        if (data.processes_id == '') {
          this.vorgang = true;
        }
        if (data.processes_id == undefined) {
          this.vorgang = true;
        }
        if (data.sparte == '') {
          this.sparte = true;
        }
        if (data.sparte == undefined) {
          this.sparte = true;
        }
        if (data.product == '') {
          this.product = true;
        }
        if (data.cm_area == '') {
          this.bereich = true;
        }
        if (data.mandant == '') {
          this.mandant = true;
        }
        if (data.mandant == undefined) {
          this.mandant = true;
        }
        if (data.actor == '') {
          this.actor = true;
        }
        if (data.actor == undefined) {
          this.actor = true;
        }
        if (data.actortype == '') {
          this.actortype = true;
        }
        if (data.actortype == undefined) {
          this.actortype = true;
        }
        if (data.from == '') {
          if (this.von_disable == true) {
            this.von = false;
          } else if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
            this.von = false;
          } else {
            this.von = true;
          }
        } else if (data.from != '' && this.serchfind == false) {
          this.validation_von_message = true;
          this.von = true;
        }
        if (data.to == '') {
          if (this.bis_disable == true) {
            this.bis = false;
          } else if (this.user_role === USER_ROLES.CUSTOMER_ADMIN) {
            this.bis = false;
          } else {
            this.bis = true;
          }
        } else if (data.to != '' && this.serchfindto == false) {
          this.validation_bis_message = true;
          this.bis = true;
        }
        if (this.isICUser) {
          if (data.extern == '' && data.editor == '') {
            this.extern_editor_message = true;
            this.extern = true;
            this.bearbeiter = true;
          } else if (
            (data.extern !== '' && data.editor == '') ||
            (data.extern == '' && data.editor !== '')
          ) {
            this.extern_editor_message = false;
            this.extern = false;
            this.bearbeiter = false;
          }
        } else if (this.isCustomerUser) {
          if (data.editor == '') {
            this.bearbeiter = true;
          }
        }
      }
    } else {
      if (data.processes_id == '') {
        this.vorgang = true;
      }
      if (data.sparte_id == '') {
        this.sparte = true;
      }
      if (data.product_id == '') {
        this.product = true;
      }
      if (data.cm_area_id == '') {
        this.bereich = true;
      }
      if (data.editor == '' || data.extern == '') {
        if (data.editor == '' && data.extern == '') {
          this.extern_editor_message = true;
          this.bearbeiter = true;
          this.extern = true;
        } else if (data.editor == '' && data.extern !== '') {
          this.extern_editor_message = false;
          this.bearbeiter = false;
          this.extern = false;
        } else if (data.extern == '' && data.editor !== '') {
          this.extern = false;
          this.bearbeiter = false;
        }
      }
      if (data.from == '' || data.to == '') {
        if (data.from == '' && data.to == '') {
          if (data.cm_area == 'Kunden') {
            this.von = false;
            this.von_validation = '';
            this.data.from = '';
            this.bis = false;
            this.bis_validation = '';
            this.data.to = '';
            this.von_disable = true;
            this.bis_disable = true;
          } else {
            this.von = true;
            this.bis = true;
            this.von_disable = false;
            this.bis_disable = false;
            if (this.cmArea == 'Kundenendnummer') {
              this.bis_validation = 'von_validation_label_Kundenendnummer';
              this.von_validation = 'von_validation_label_Kundenendnummer';
            } else if (this.cmArea == 'Kundennummer') {
              this.bis_validation = 'von_validation_label_Kundennummer';
              this.von_validation = 'von_validation_label_Kundennummer';
            } else if (this.cmArea == 'Nachname') {
              this.bis_validation = 'von_validation_label_Nachname';
              this.von_validation = 'von_validation_label_Nachname';
            } else if (this.cmArea == 'Firmenname') {
              this.bis_validation = 'von_validation_label_Firmenname';
              this.von_validation = 'von_validation_label_Firmenname';
            } else if (this.cmArea == 'Postleitzahl') {
              this.bis_validation = 'von_validation_label_Postleitzahl';
              this.von_validation = 'von_validation_label_Postleitzahl';
            } else {
              this.bis_validation = 'von_validation_label';
              this.von_validation = 'von_validation_label';
            }
          }
        } else if (data.from == '') {
          this.von = true;
          this.bis = false;
        } else if (data.bis == '') {
          this.bis = true;
          this.von = false;
        }
      }
    }
  }
  getSparteNamesList(data): string {
    return data.join('\n');
  }
}
