import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { NewEditorComponent } from '../new-editor/new-editor.component';
import { SubSendingElement } from '../contact-manager.component';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { ContactManagerSharedService } from '@core/services/contact-manager-shared.service';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
// interface is for sending filter and first step data to second step
export interface SecondStepData {
  mandant: string;
  actortype: string;
  actor: string;
  subsidiary: string;
  actortypename: string;
  actorname: string;
  mandantname: string;
  subsidiaryname: string;
  contact_manager_id: string;
  processes_id: string;
  cm_area_id: string;
  sparte_id: string;
  editor_id: string;
  product_id: string;
  subsidiary_id: string;
  extern_id: string;
  creator_id: string;
  user_role_id: string;
  process: string;
  sparte: string;
  product: string;
  cm_area: string;
  from: string;
  to: string;
  extern: string;
  editor: string;
  email: string;
  telephone: string;
  fromWhich: string;
  title: string;
  fixer_code: string;
  buttonConfirmName: string;
  buttonCancelName: string;
  is_sparte_group: any;
}

@Component({
  selector: 'app-step-one-process',
  templateUrl: './step-one-process.component.html',
  styleUrls: ['./step-one-process.component.scss'],
})
export class StepOneProcessComponent extends BaseComponent implements OnInit {
  divisionPreSelected = false;
  Actor_type_PreSelected = false;
  Actor_PreSelected = false;
  userid: any;
  telephone: any;
  email: any;
  userDataValue: LoggedInUser;
  user_role: USER_ROLES;
  allDivision: any;
  actor_type_disable: boolean;
  selectedDivisionId: any;
  selectedDivision: any;
  filterselectedDivisionId: any;
  filterselectedActorTypeId: any;
  filterselectedActorId: any;
  filterselectedSubsidiary: any;
  actorTypes: any;
  actorTypesNew: any;
  subsidiary_disable: boolean;
  actor_disable = true;
  selectedActorType: any;
  selectedActorTypeId: any;
  actors: any = [];
  message: string;
  division_id: any;
  customers: any = [];
  sendMandantName: any;
  sendActorName: any;
  sendActorType: any;
  subsidiary_id: any;
  sendSubsidiaryName: any;
  searchForm: FormGroup;
  GowithEmptySub = false;
  goWithMandant: boolean;
  goWithActorType: boolean;
  goWithActor: boolean;
  message_companies: string;
  mandant_color: boolean;
  actor_color: boolean;
  actor_type_color: boolean;
  actor_type_selected_name: any;
  sparte_array: any;
  is_sparte_group: any;
  actor_selected_name: any;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<StepOneProcessComponent>,
    public serviceCall: ApiCallsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public validationService: ValidationService,
    private readonly _userService: UserService,
    private readonly _contactManagerSharedService: ContactManagerSharedService
  ) {
    super();
    // storing data received from filter and calling needed apis
    this.userDataValue = this._userService.getLoggedInUser();
    this.user_role = this._userService.getUserRole();
    this.filterselectedDivisionId = data.mandant;
    this.division_id = this.filterselectedDivisionId;
    this.filterselectedActorTypeId = data.actortype;
    this.filterselectedActorId = data.actor;
    this.filterselectedSubsidiary = data.subsidiary;
    if (data.actortypename !== '' && data.actortypename !== undefined) {
      this.actor_disable = false;
      this.serviceCall
        .getActor(
          data.actortypename,
          data.mandant,
          this.user_role,
          this.userDataValue.user_profile.user_data.user_id
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.actors.length) {
            this.message = '';
            this.actors = data.actors;
            if (data.actors.length == 1) {
              this.Actor_PreSelected = true;
              this.actor_selected_name = data.actors[0].actor_id;
              this.sendActorName = data.actors[0].actor_name;
            }
          } else {
            this.actors = [];
            this.message = 'KeinAkteur_info';
          }
        });
    }
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      mandant: new FormControl('', [Validators.required]),
      actor: new FormControl('', [Validators.required]),
      subsidiary: new FormControl('', [Validators.required]),
      actorType: new FormControl('', [Validators.required]),
    });
    this.loadMandant();
    this.loadSparte();
  }
  loadSparte() {
    // previously we were sending parent id and its value was null so sending null here as customer_id.
    this.serviceCall
      .getSparte(
        this.userDataValue.user_profile.customer_details.division_id,
        null,
        this.user_role
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.sparte_array = data.sparte;
      });
  }
  // function for division data
  loadMandant() {
    this.userid = this._userService.getUserId();
    this.telephone = this.userDataValue.user_profile.user_data.mobile_number;
    this.email = this.userDataValue.user_profile.user_data.email_id;
    if (this.filterselectedDivisionId == null) {
      this.serviceCall
        .getDivision(this.userid)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.allDivision = data.divisions;
          if (data.divisions.length == 1) {
            this.divisionPreSelected = true;
            this.actor_type_disable = false;
            this.selectedDivision = data.divisions[0].division_name;
            this.selectedDivisionId = data.divisions[0].division_id;
            this.division_id = this.selectedDivisionId;
            this.filterselectedDivisionId = this.selectedDivisionId;
          }
        });
    } else {
      this.selectedDivisionId = this.filterselectedDivisionId;
      this.division_id = this.filterselectedDivisionId;
      this.serviceCall
        .getDivision(this.userid)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.allDivision = data.divisions;
          // condition for single value of division
          if (data.divisions.length == 1) {
            this.divisionPreSelected = true;
            this.actor_type_disable = false;
            this.selectedDivision = data.divisions[0].division_name;
            this.selectedDivisionId = data.divisions[0].division_id;
            this.filterselectedDivisionId = this.selectedDivisionId;
          }
          this.serviceCall
            .getActorTyp(this.user_role)
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
              this.actorTypes = data.actor_types;
              this.actorTypesNew = data.actor_types.filter(
                (actorType: any) => actorType.actor_type_name == "Kunde"
              );
              this.subsidiary_disable = false;
              if (data.actor_types.length == 1) {
                this.Actor_type_PreSelected = true;
                this.actor_disable = false;
                this.subsidiary_disable = false;
                this.selectedActorType = data.actor_types[0].actor_type_name;
                this.selectedActorTypeId = data.actor_types[0].actorTypeId;
                this.saveActorTypeId(
                  this.selectedActorTypeId,
                  this.selectedActorType,
                  this.filterselectedDivisionId
                );
              }
              if (
                this.filterselectedActorTypeId !== null &&
                this.filterselectedActorTypeId !== ''
              ) {
                for (let i = 0; i < this.actorTypes.length; i++) {
                  if (
                    this.actorTypes[i].actorTypeId ==
                    this.filterselectedActorTypeId
                  ) {
                    this.selectedActorType = this.actorTypes[i].actor_type_name;
                  }
                }
                this.serviceCall
                  .getActor(
                    this.selectedActorType,
                    this.filterselectedDivisionId,
                    this.user_role,
                    this.userid
                  )
                  .pipe(takeUntil(this._destroy$))
                  .subscribe(data => {
                    if (data.actors.length > 0) {
                      this.message = '';
                      this.actors = data.actors;
                      if (data.actors.length == 1) {
                        this.Actor_PreSelected = true;
                        this.actor_selected_name = data.actors[0].actor_id;
                        this.sendActorName = data.actors[0].actor_name;
                      }
                    } else {
                      this.actors = [];
                      this.message = 'KeinAkteur_info';
                    }
                    if (
                      this.filterselectedActorId !== null &&
                      this.filterselectedActorId !== ''
                    ) {
                      for (let k = 0; k < this.actorTypes.length; k++) {
                        if (
                          this.actorTypes[k].actorTypeId ==
                          this.filterselectedActorTypeId
                        ) {
                          this.actor_type_selected_name =
                            this.actorTypes[k].actor_type_name;
                        }
                      }
                      const subsidiarySendingData: SubSendingElement = {
                        divisionId: this.filterselectedDivisionId,
                        actor_type: this.selectedActorType,
                        liztu_vn_kdnr: this.filterselectedActorId,
                        role: this.user_role,
                      };
                      if (this.actor_type_selected_name !== 'IC') {
                        this.serviceCall
                          .getSubsidiary(subsidiarySendingData)
                          .pipe(takeUntil(this._destroy$))
                          .subscribe(data => {
                            this.customers = data.customers;
                          });
                      }
                    }
                  });
              }
            });
        });
    }
  }
  onNoClick() {
    // removing value when we discard the changes
    this._contactManagerSharedService.discardAllValues();
    this.dialogRef.close();
  }
  // call when user click on next
  nextStep(data) {
    if (this.Actor_PreSelected == true) {
      data.actor = this.actor_selected_name;
    }
    if (this.divisionPreSelected == true) {
      data.mandantname = this.selectedDivision;
      this.sendMandantName = this.selectedDivision;
      data.mandant = this.selectedDivisionId;
    }

    if (data.mandant !== '' && data.mandant !== null) {
      this.goWithMandant = true;
    }
    if (data.actortype !== '' && data.actortype !== null) {
      this.goWithActorType = true;
    }
    if (data.actor !== '' && data.actor !== null) {
      this.goWithActor = true;
    }

    if (
      this.goWithMandant == true &&
      this.goWithActorType == true &&
      this.goWithActor == true
    ) {
      this.mandant_color = false;
      this.actor_color = false;
      this.actor_type_color = false;

      // data loading for second step of cm
      const secondStepData: SecondStepData = {
        mandant: data.mandant,
        actortype: data.actortype,
        actor: data.actor,
        subsidiary: data.subsidiary,
        actortypename:
          this.sendActorType !== undefined
            ? this.sendActorType
            : data.actortypename,
        actorname:
          this.sendActorName !== undefined
            ? this.sendActorName
            : data.actorname,
        mandantname:
          this.sendMandantName !== undefined
            ? this.sendMandantName
            : data.mandantname,
        subsidiaryname:
          this.sendSubsidiaryName !== undefined
            ? this.sendSubsidiaryName
            : data.subsidiaryname,
        contact_manager_id: '',
        processes_id: data.processes_id !== undefined ? data.processes_id : '',
        cm_area_id: '',
        sparte_id: data.sparte_id !== undefined ? data.sparte_id : '',
        editor_id: '',
        product_id: '',
        subsidiary_id: '',
        extern_id: '',
        creator_id: '',
        user_role_id: '',
        process: data.process !== undefined ? data.process : '',
        sparte: data.sparte !== undefined ? data.sparte : '',
        product: '',
        cm_area: '',
        from: '',
        to: '',
        extern: '',
        editor: '',
        email: '',
        telephone: '',
        fromWhich: 'add',
        title: '',
        fixer_code: '',
        buttonConfirmName: '',
        buttonCancelName: '',
        is_sparte_group: data.is_sparte_group,
      };
      this.dialog
        .open(NewEditorComponent, {
          panelClass: 'processDialog',
          width: '800px',
          data: secondStepData,
          disableClose: true,
        })
        .afterClosed()
        .subscribe(s => {
          if (s) {
            this.dialogRef.close(s);
          }
        });
    } else {
      if (this.goWithMandant !== true) {
        this.mandant_color = true;
      }
      if (this.goWithActor !== true) {
        this.actor_color = true;
      }
      if (this.goWithActorType !== true) {
        this.actor_type_color = true;
      }
    }
  }
  // save selected mandant data
  getMandantId(value) {
    this.mandant_color = false;
    this.sendActorType = '';
    this.sendActorName = '';
    this.sendSubsidiaryName = '';
    this.goWithActor = false;
    this.goWithActorType = false;
    this.division_id = value.value;
    this.searchForm.controls.actor.reset();
    this.actors = [];
    this.message = '';
    this.searchForm.controls.actorType.reset();
    this.searchForm.controls.subsidiary.reset();
    this.customers = [];
    this.sendMandantName = value.source.triggerValue;
    this.serviceCall
      .getActorTyp(this.user_role)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.actorTypes = data.actor_types;
        this.actorTypesNew = data.actor_types.filter(
          (actorType: any) => actorType.actor_type_name == "Kunde"
        );
        this.subsidiary_disable = false;
        if (data.actor_types.length == 1) {
          this.Actor_type_PreSelected = true;
          this.actor_disable = false;
          this.subsidiary_disable = false;
          this.selectedActorType = data.actor_types[0].actor_type_name;
          this.selectedActorTypeId = data.actor_types[0].actorTypeId;
          this.saveActorTypeId(
            this.selectedActorTypeId,
            this.selectedActorType,
            value.value
          );
        }
      });
  }
  // save selected subsidiary data
  saveSubsidiary(value) {
    this.subsidiary_id = value.value;
    this.sendSubsidiaryName = value.source.triggerValue;
  }
  // save selected actor type id when data is preselected
  saveActorTypeId(
    selectedActorTypeId: any,
    selectedActorType: any,
    divisionID: any
  ) {
    throw new Error('Method not implemented.');
  }
  // save selected  actor type
  actor(value) {
    this._contactManagerSharedService.editorId = null;
    this._contactManagerSharedService.editorName = null;
    this._contactManagerSharedService.telephone = null;
    this._contactManagerSharedService.email = null;
    this.actor_type_color = false;
    if (
      this.filterselectedDivisionId !== '' &&
      this.filterselectedDivisionId !== undefined
    ) {
      if (this.division_id == '') {
        this.division_id = this.filterselectedDivisionId;
      }
    }
    this.sendActorName = '';
    this.sendSubsidiaryName = '';
    this.goWithActor = false;
    this.actor_disable = false;

    this.searchForm.controls.actor.reset();
    this.actors = [];
    this.searchForm.controls.subsidiary.reset();
    if (value.source.triggerValue == 'Kunde') {
      this.actor_type_selected_name = value.source.triggerValue;
      this.subsidiary_disable = false;
    } else {
      this.actor_type_selected_name = value.source.triggerValue;
      this.subsidiary_disable = true;
      this.customers = [];
    }
    this.selectedActorType = value.source.triggerValue;
    this.sendActorType = value.source.triggerValue;
    this.serviceCall
      .getActor(
        this.selectedActorType,
        this.division_id,
        this.user_role,
        this.userid
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.actors.length > 0) {
          this.message = '';
          this.actors = data.actors;
          if (data.actors.length == 1) {
            this.Actor_PreSelected = true;
            this.actor_selected_name = data.actors[0].actor_id;
            this.sendActorName = data.actors[0].actor_name;
          }
        } else {
          this.actors = [];
          this.message = 'KeinAkteur_info';
        }
      });
  }
  // save actor data
  saveActor(value) {
    this.actor_color = false;

    this.searchForm.controls.subsidiary.reset();
    this.subsidiary_id = '';
    this.sendSubsidiaryName = '';
    this.data.subsidiary = '';
    this.sendActorName = value.source.triggerValue;
    const subsidiarySendingData: SubSendingElement = {
      divisionId: this.division_id,
      actor_type: this.selectedActorType,
      liztu_vn_kdnr: value.value,
      role: this.user_role,
    };
    if (this.actor_type_selected_name !== 'IC') {
      this.serviceCall
        .getSubsidiary(subsidiarySendingData)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data && data.customers && data.customers.length > 0) {
            this.message_companies = '';
            this.customers = data.customers;
          } else {
            this.customers = [];
            this.message_companies = 'company_info';
          }
        });
    } else {
      this.customers = [];
    }
  }
}
