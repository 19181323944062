const FOLDER_CONST = {
    ICICONS: 'assets/images/ic-icons/'
}

export const IC_ICONS = {
    APPOINTMENTS: `${FOLDER_CONST.ICICONS}Appointments.svg`,
    BANK: `${FOLDER_CONST.ICICONS}bank.svg`,
    CALENDER: `${FOLDER_CONST.ICICONS}Calendar.svg`,
    CLAIMS: `${FOLDER_CONST.ICICONS}Claims.svg`,
    CLOCK: `${FOLDER_CONST.ICICONS}Clock.svg`,
    CLOCK1: `${FOLDER_CONST.ICICONS}Clock-1.svg`,
    COMMISIONS_BROKERAGE: `${FOLDER_CONST.ICICONS}Commissions_Brokerage.svg`,
    COMMUNICATION: `${FOLDER_CONST.ICICONS}Communication.svg`,
    CONTACT_MANAGER: `${FOLDER_CONST.ICICONS}Contact Manager.svg`,
    CONTACT_PERSON: `${FOLDER_CONST.ICICONS}Contact Person.svg`,
    CONTRACTS: `${FOLDER_CONST.ICICONS}Contracts.svg`,
    CREATE_CONTRACT: `${FOLDER_CONST.ICICONS}Create Contract.svg`,
    CUSTOMER_DETAILS: `${FOLDER_CONST.ICICONS}Customer Details.svg`,
    CUSTOMER_RELATIONS: `${FOLDER_CONST.ICICONS}Customer Relations.svg`,
    CUSTOMERS: `${FOLDER_CONST.ICICONS}Customers.svg`,
    DASHBOARD_CONFIGURATION: `${FOLDER_CONST.ICICONS}Dashboard Configuration.svg`,
    DASHBOARD: `${FOLDER_CONST.ICICONS}Dashboard.svg`,
    DELETE: `${FOLDER_CONST.ICICONS}Delete.svg`,
    DOCUMENT_MANAGEMENT: `${FOLDER_CONST.ICICONS}Document Management.svg`,
    DOCUMENTS: `${FOLDER_CONST.ICICONS}Documents.svg`,
    DRAG: `${FOLDER_CONST.ICICONS}Drag.svg`,
    DROPDOWN: `${FOLDER_CONST.ICICONS}Dropdown.svg`,
    EDIT: `${FOLDER_CONST.ICICONS}Edit.svg`,
    EXPORT_TO_EXCEL: `${FOLDER_CONST.ICICONS}Export to Excel.svg`,
    FORM_GENERATOR: `${FOLDER_CONST.ICICONS}Form Generator.svg`,
    GENERAL_DATA: `${FOLDER_CONST.ICICONS}General Data.svg`,
    GEOLOCATION: `${FOLDER_CONST.ICICONS}Geolocation.svg`,
    INSURERE_MANAGEMENT:`${FOLDER_CONST.ICICONS}Insurer  Management.svg`,
    INTERMEDIARY_MANAGEMENT:`${FOLDER_CONST.ICICONS}Intermediary Management.svg`,
    MENU: `${FOLDER_CONST.ICICONS}Menu.svg`,
    NOTIFICATION:`${FOLDER_CONST.ICICONS}Notification.svg`,
    OBJECT_MANAGEMENT:`${FOLDER_CONST.ICICONS}Object Management.svg`,
    OBJECT: `${FOLDER_CONST.ICICONS}Object.svg`,
    PROCESS_MANAGEMENT: `${FOLDER_CONST.ICICONS}Process Management.svg`,
    PRODUCT_MANAGEMENT: `${FOLDER_CONST.ICICONS}Product Management.svg`,
    SEARCH: `${FOLDER_CONST.ICICONS}Search.svg`,
    SETTINGS: `${FOLDER_CONST.ICICONS}Settings.svg`,
    SORT: `${FOLDER_CONST.ICICONS}Sort.svg`,
    SPARTE_MANAGEMENT: `${FOLDER_CONST.ICICONS}Sparte Management.svg`,
    SPARTE: `${FOLDER_CONST.ICICONS}Sparte.svg`,
    TABLE_MANAGEMENT: `${FOLDER_CONST.ICICONS}Table Management.svg`,
    TEMPLATE_REPOSITORY: `${FOLDER_CONST.ICICONS}Template Repository.svg`,
    TODO: `${FOLDER_CONST.ICICONS}To do.svg`,
    TOGGLE: `${FOLDER_CONST.ICICONS}Toggle.svg`,
    UPLOAD: `${FOLDER_CONST.ICICONS}Upload.svg`,
    USER_GUIDE: `${FOLDER_CONST.ICICONS}User Guide.svg`,
    USER_MANAGEMENT: `${FOLDER_CONST.ICICONS}User Management.svg`,
    WRITE_AN_EMAIL: `${FOLDER_CONST.ICICONS}Write an Email.svg`,
    WRITE_LETTER: `${FOLDER_CONST.ICICONS}Write Letter.svg`,
    UNDER_CONSTRUCTION: `${FOLDER_CONST.ICICONS}Under_Construction.svg`,

    ORG_TREE: `${FOLDER_CONST.ICICONS}Tree.svg`,
    MOTHER_COMPANY: `${FOLDER_CONST.ICICONS}Mother.svg`,
    CHILD_COMPANY: `${FOLDER_CONST.ICICONS}Child.svg`,
    IC_SETUP: `${FOLDER_CONST.ICICONS}Ic_Setup.svg`,
    TO_ERP: `${FOLDER_CONST.ICICONS}To_ERP.svg`,

    APPOINTMENT_DISABLE: `${FOLDER_CONST.ICICONS}Appointment_Disable.svg`,
    APPOINTMENT_ENABLE: `${FOLDER_CONST.ICICONS}Appointment_Enable.svg`,
    APPOINTMENT_SELECTED: `${FOLDER_CONST.ICICONS}Appointment_Selected.svg`,

    DOCUMENT_MANAGEMENT_DISABLE: `${FOLDER_CONST.ICICONS}Document_Management_Disable.svg`,
    DOCUMENT_MANAGEMENT_ENABLE: `${FOLDER_CONST.ICICONS}Document_Management_Enable.svg`,
    DOCUMENT_MANAGEMENT_SELECTED: `${FOLDER_CONST.ICICONS}Document_Management_Selected.svg`,
    DOCUMENT_MANAGEMENT_NEW: `${FOLDER_CONST.ICICONS}Document_Management_New.svg`,
    DOCUMENT_MANAGEMENT_ACTIVE_NEW: `${FOLDER_CONST.ICICONS}Document_Management_Active_New.svg`,

    USER_MANAGEMENT_DISABLE: `${FOLDER_CONST.ICICONS}User_Management_Disable.svg`,
    USER_MANAGEMENT_ENABLE: `${FOLDER_CONST.ICICONS}User_Management_Enable.svg`,
    USER_MANAGEMENT_SELECTED: `${FOLDER_CONST.ICICONS}User_Management_Selected.svg`,
    USER_MANAGEMENT_NEW: `${FOLDER_CONST.ICICONS}User_Management_New.svg`,
    USER_MANAGEMENT_ACTIVE_NEW: `${FOLDER_CONST.ICICONS}User_Management_Active_New.svg`,

    CONTRACT_DISABLE: `${FOLDER_CONST.ICICONS}Contract_Disable.svg`,
    CONTRACT_ENABLE: `${FOLDER_CONST.ICICONS}Contract_Enable.svg`,
    CONTRACT_SELECTED: `${FOLDER_CONST.ICICONS}Contract_Selected.svg`,
    CONTRACT_ACTIVE_NEW: `${FOLDER_CONST.ICICONS}Contract_Active_New.svg`,
    
    OBJECT_MANAGEMENT_DISABLE: `${FOLDER_CONST.ICICONS}Object_Management_Disable.svg`,
    OBJECT_MANAGEMENT_ENABLE: `${FOLDER_CONST.ICICONS}Object_Management_Enable.svg`,
    OBJECT_MANAGEMENT_SELECTED: `${FOLDER_CONST.ICICONS}Object_Management_Selected.svg`,
    OBJECT_MANAGEMENT_NEW: `${FOLDER_CONST.ICICONS}Object_Management_New.svg`,
    OBJECT_MANAGEMENT_ACTIVE_NEW: `${FOLDER_CONST.ICICONS}Object_Management_Active_New.svg`,

    CLAIM_DISABLE: `${FOLDER_CONST.ICICONS}Claim_Disable.svg`,
    CLAIM_ENABLE: `${FOLDER_CONST.ICICONS}Claim_Enable.svg`,
    CLAIM_SELECTED: `${FOLDER_CONST.ICICONS}Claim_Selected.svg`,
    CLAIM_NEW: `${FOLDER_CONST.ICICONS}Claim_New.svg`,
    CLAIMS_ACTIVE_NEW: `${FOLDER_CONST.ICICONS}Claims_Active_New.svg`,

    INSURER_MANAGEMENT_DISABLE: `${FOLDER_CONST.ICICONS}Insurer_Management_Disable.svg`,
    INSURER_MANAGEMENT_ENABLE: `${FOLDER_CONST.ICICONS}Insurer_Management_Enable.svg`,
    INSURER_MANAGEMENT_SELECTED: `${FOLDER_CONST.ICICONS}Insurer_Management_Selected.svg`,
    INSURER_MANAGEMENT_NEW: `${FOLDER_CONST.ICICONS}Insurer_Management_New.svg`,
    INSURER_MANAGEMENT_ACTIVE_NEW: `${FOLDER_CONST.ICICONS}Insurer_Management_Active_New.svg`,

    INTERMEDIARY_MANAGEMENT_DISABLE: `${FOLDER_CONST.ICICONS}Intermediary_Management_Disable.svg`,
    INTERMEDIARY_MANAGEMENT_ENABLE: `${FOLDER_CONST.ICICONS}Intermediary_Management_Enable.svg`,
    INTERMEDIARY_MANAGEMENT_SELECTED: `${FOLDER_CONST.ICICONS}Intermediary_Management_Selected.svg`,
    INTERMEDIARY_MANAGEMENT_ACTIVE_NEW: `${FOLDER_CONST.ICICONS}Intermediary_Management_Active_New.svg`,
    
    
    APPOINTMENT_NEW: `${FOLDER_CONST.ICICONS}Appointment_New.svg`,
    APPOINTMENT_ACTIVE_NEW: `${FOLDER_CONST.ICICONS}Appointment_Active_New.svg`,
}