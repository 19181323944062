import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import { DocumentManagementService } from '@core/services/document-management.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { DocumentInformation } from '../../models/document-management.model';
import {
  DocumentColumns,
  DocumentExtension,
  GetDocumentsParams,
  VersionedDocument,
} from './document-table.model';
import { BehaviorSubject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { ToastService } from '@core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { BaseComponent } from '../base.component';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { UserService } from '@core/user.service';
import { filterNil } from '../../utils/filter-nil.pipe';
import { Utils } from '../../utils/utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-documents-table',
  styleUrls: ['documents-table.component.scss'],
  templateUrl: 'documents-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsTableComponent extends BaseComponent implements OnInit {
  @Input() canEdit = false;

  @Input() set params(params: GetDocumentsParams) {
    this._params = params;
    this._documentManagementService.refresh();
  }
  @Input() set customerVisibilityHidden(value: boolean) {
    this._visibleForCustomer = false;
  }

  @Output() deleteId: EventEmitter<string> = new EventEmitter();

  readonly displayedColumns = [
    DocumentColumns.EXPAND,
    DocumentColumns.DOCUMENT_TYPE,
    DocumentColumns.DESCRIPTION,
    DocumentColumns.CREATED_BY,
    DocumentColumns.SOURCE_TARGET,
    DocumentColumns.UPLOAD_DATE,
    DocumentColumns.ACTION,
  ];
  readonly documents$: BehaviorSubject<VersionedDocument[]> =
    new BehaviorSubject([]);
  private readonly _initialDocs$: BehaviorSubject<VersionedDocument[]> =
    new BehaviorSubject([]);
  private _params: GetDocumentsParams;

  private readonly _isShouldShowWarning$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  readonly isShouldShowWarning$ = this._isShouldShowWarning$.asObservable();
  private readonly _documentIdToDelete$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);
  readonly documentIdToDelete$ = this._documentIdToDelete$.asObservable();

  private readonly _pdfLink$: BehaviorSubject<string> = new BehaviorSubject('');
  readonly pdfLink$ = this._pdfLink$.asObservable();
  private readonly _isShouldShowPdfModal$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  readonly isShouldShowPdfModal$ = this._isShouldShowPdfModal$.asObservable();

  private readonly _isICUser$: BehaviorSubject<Boolean> =
    new BehaviorSubject(false);
  readonly isICUser$ = this._isICUser$.asObservable();

  private readonly _blobStream$: BehaviorSubject<Blob> = new BehaviorSubject(
    null
  );
  readonly blobStream$ = this._blobStream$.asObservable();

  private _userRole: USER_ROLES;
  private _visibleForCustomer = true;

  constructor(
    private readonly _documentsApiService: DocumentManagementApiService,
    private readonly _documentManagementService: DocumentManagementService,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService,
    private readonly _apicallService: ApiCallsService,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this._visibleForCustomer) {
      this.displayedColumns.splice(1, 0, DocumentColumns.VISIBLE_FOR_CUSTOMER);
    }

    this._watchOnUserRole();
    this._listenRefreshDocs();
  }

  switch({ documentId, isOpened }: VersionedDocument): void {
    isOpened
      ? this._removeVersions(documentId)
      : this._documentsApiService
          .getVersions(documentId)
          .pipe(takeUntil(this._destroy$))
          .subscribe(response =>
            this._addVersions(response.documents, documentId)
          );
  }

  replace(document: VersionedDocument): void {
    this._documentManagementService.setDocumentToReplace(document);
    this._documentManagementService.openDialog();
  }

  showSummary(document: VersionedDocument): void {
    this._documentManagementService.setDocumentToSummarize(document);
    this._documentManagementService.openDialog();
  }

  redirectToLuna(): void {
    window.open("https://luna-demo.planet-ai.cloud/", "_blank");
  }

  sortData({ active, direction }: Sort): void {
    if (!active || direction === '') {
      this.documents$.next(this._initialDocs$.value.slice());
      return;
    }
    const docs = this.documents$.value
      .slice()
      .filter(document => !document.parentId)
      .map(item => ({ ...item, isOpened: false }));
    const isAsc = direction === 'asc';
    const sorted = docs
      .filter(document => !document.parentId)
      .sort((f1: VersionedDocument, f2: VersionedDocument) => {
        switch (active) {
          case DocumentColumns.VISIBLE_FOR_CUSTOMER:
            return this._compare(
              String(f1.visibleForCustomer),
              String(f2.visibleForCustomer),
              isAsc
            );
          case DocumentColumns.DOCUMENT_TYPE:
            return this._compare(f1.documentType, f2.documentType, isAsc);
          case DocumentColumns.DESCRIPTION:
            return this._compare(f1.description, f2.description, isAsc);
          case DocumentColumns.CREATED_BY:
            return this._compare(f1.createdBy, f2.createdBy, isAsc);
          case DocumentColumns.SOURCE_TARGET:
            return this._compare(
              `${f1.sourceUser}/${f1.targetUser}`,
              `${f2.sourceUser}/${f2.targetUser}`,
              isAsc
            );
          case DocumentColumns.UPLOAD_DATE:
            return Utils.compareDates(f1.uploadDate, f2.uploadDate, isAsc);
        }
      });
    this.documents$.next(sorted);
  }

  callDeleteDialog({ documentId }: VersionedDocument): void {
    this._isShouldShowWarning$.next(true);
    this._documentIdToDelete$.next(documentId);
  }

  editMetaData(info: VersionedDocument): void {
    this._documentManagementService.setDocumentToUpdate(info);
    this._documentManagementService.openDialog();
  }

  deleteDoc(): void {
    this._isShouldShowWarning$.next(false);
    this._documentsApiService
      .deleteDocument(this._documentIdToDelete$.value)
      .pipe(takeUntil(this._destroy$))
      .subscribe(({ HasErrors, message }) => {
        this._toastService.showToastMessage(
          document,
          HasErrors
            ? message
            : this._translateService.instant(
                'objectModule.documentDeleteMessage'
              )
        );
        this._documentIdToDelete$.next(null);
        this._documentManagementService.refresh();
      });
  }

  closeWarning(): void {
    this._isShouldShowWarning$.next(false);
    this._documentIdToDelete$.next(null);
  }

  openOfficeLink({ draftLink }: DocumentInformation): void {
    window.open(draftLink, '_blank')?.focus();
  }

  sendDraftToAzure({ documentId, documentName }: DocumentInformation): void {
    const request =
      Utils.getFileExtension(documentName) === DocumentExtension.EMAIL
        ? this._documentsApiService.sendOutlookDraftToAzure(documentId)
        : this._documentsApiService.sendWordDraftToAzure(documentId);
    request.pipe(takeUntil(this._destroy$)).subscribe(
      () => {
        this._documentManagementService.refresh();
        this._toastService.showToastMessage(
          document,
          this._translateService.instant('office.letter.successful_message')
        );
      },
      (error: HttpErrorResponse) =>
        this._toastService.showToastMessage(document, error.error.message)
    );
  }

  getSecuredDocument(document: DocumentInformation): void {
    this._apicallService
      .getSecureDocument({ uuid: document.uuid })
      .pipe(takeUntil(this._destroy$))
      .subscribe((blobStream: Blob) =>
        this._actionConsiderExtension(document, blobStream)
      );
  }

  closePdfModal(): void {
    this._isShouldShowPdfModal$.next(false);
  }

  private _actionConsiderExtension(
    { documentName, content_type }: DocumentInformation,
    blobStream: Blob
  ): void {
    if (content_type === DocumentExtension.PDF) {
      this._openPdf(blobStream);
    } else if (
      Utils.getFileExtension(documentName) === DocumentExtension.EMAIL
    ) {
      Utils.downloadFile(blobStream, documentName);
    } else {
      const objectURL = window.URL.createObjectURL(blobStream);
      window.open(objectURL);
    }
  }

  private _openPdf(blobStream: Blob): void {
    const fileURL = window.URL.createObjectURL(blobStream);
    this._pdfLink$.next(fileURL);
    this._isShouldShowPdfModal$.next(true);
    this._blobStream$.next(blobStream);
  }

  private _listenRefreshDocs(): void {
    this._documentManagementService.refreshDocuments$
      .pipe(
        switchMap(() => {
          const {
            source,
            VN_KDNR,
            VS_IDNR,
            SA_IDNR,
            customerId,
            policy,
            contractId,
            claimId,
            insurerId,
            intermediaryId,
          } = this._params;
          return this._documentsApiService.getDocuments(
            source,
            VN_KDNR,
            VS_IDNR,
            SA_IDNR,
            customerId,
            policy,
            contractId,
            claimId,
            false,
            insurerId,
            intermediaryId
          );
        }),
        map(data => this._appendVersionFields(data.documents)),
        map((data: VersionedDocument[]) =>
          data.sort((f1: VersionedDocument, f2: VersionedDocument) =>
            Utils.compareDates(f1.uploadDate, f2.uploadDate, false)
          )
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(docs => {
        this.documents$.next(docs);
        this._initialDocs$.next(docs);
      });
  }

  private _compare(
    firstValue: string,
    secondValue: string,
    isAsc: boolean
  ): number {
    return (
      (firstValue?.toLowerCase() < secondValue?.toLowerCase() ? -1 : 1) *
      (isAsc ? 1 : -1)
    );
  }

  private _updateUserInfo(): void {
    this._isICUser$.next(!ROLES.CUSTOMER_ROLES.includes(this._userRole));
  }

  private _addVersions(
    children: DocumentInformation[],
    parentId: number
  ): void {
    if (children.length) {
      const docs = [...this.documents$.value];
      const parentIndex = docs.findIndex(
        document => document.documentId === parentId
      );
      if (parentIndex !== -1) {
        const master = { ...docs[parentIndex], isOpened: true };
        const newDocumentsArray = [
          ...docs.slice(0, parentIndex),
          master,
          ...children.map(item => ({ ...item, parentId, isOpened: true })),
          ...docs.slice(parentIndex + 1),
        ];
        this.documents$.next(newDocumentsArray);
      }
    }
  }

  private _removeVersions(parentId: number): void {
    const docs = [...this.documents$.value];
    const parentIndex = docs.findIndex(
      document => document.documentId === parentId
    );
    const master = { ...docs[parentIndex], isOpened: false };

    const amountOfChildren = docs.filter(
      document => document.parentId === parentId
    ).length;

    const newDocumentsArray = [
      ...docs.slice(0, parentIndex),
      master,
      ...docs.slice(parentIndex + amountOfChildren + 1),
    ];
    this.documents$.next(newDocumentsArray);
  }

  private _appendVersionFields(
    documents: DocumentInformation[]
  ): VersionedDocument[] {
    return documents.map(document => ({
      isOpened: false,
      parentId: null,
      ...document,
    }));
  }

  private _removeColumnForCustomers(): void {
    if (ROLES.CUSTOMER_ROLES.includes(this._userRole)) {
      this.displayedColumns.splice(2, 1);
    } // remove the first column for customer
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this._userRole = role;
        this._removeColumnForCustomers();
        this._updateUserInfo();
      });
  }
}
