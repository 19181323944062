import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UploadDocumentData } from '@shared/components/upload-document-popup/upload-document-popup.model';
import { VersionedDocument } from '@shared/components/documents-table/document-table.model';

@Injectable({ providedIn: 'root' })
export class DocumentManagementService {
  private readonly _refreshDocuments$: BehaviorSubject<null> =
    new BehaviorSubject(null);
  readonly refreshDocuments$ = this._refreshDocuments$.asObservable();

  private readonly _isDialogOpened$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  readonly isDialogOpened$ = this._isDialogOpened$.asObservable();

  private readonly _isShowVisibilityFlag$: BehaviorSubject<boolean> =
    new BehaviorSubject(true);
  readonly isShowVisibilityFlag$ = this._isShowVisibilityFlag$.asObservable();

  private readonly _fieldsUploadingDocuments$: BehaviorSubject<UploadDocumentData | null> =
    new BehaviorSubject<UploadDocumentData | null>(null);
  readonly fieldsUploadingDocuments$ =
    this._fieldsUploadingDocuments$.asObservable();

  private readonly _documentToUpdate$: BehaviorSubject<VersionedDocument | null> =
    new BehaviorSubject<VersionedDocument | null>(null);
  readonly documentToUpdate$ = this._documentToUpdate$.asObservable();

  private readonly _documentToReplace$: BehaviorSubject<VersionedDocument | null> =
    new BehaviorSubject<VersionedDocument | null>(null);
  readonly documentToReplace$ = this._documentToReplace$.asObservable();

  private readonly _documentToSummarize$: BehaviorSubject<VersionedDocument | null> =
    new BehaviorSubject<VersionedDocument | null>(null);
  readonly documentToSummarize$ = this._documentToSummarize$.asObservable();

  refresh(): void {
    this._refreshDocuments$.next(null);
  }

  openDialog(): void {
    this._isDialogOpened$.next(true);
  }

  openDialogWithVisibilityFlagHidden(): void {
    this.openDialog();
    this.hideVisibilityFlag();
  }

  closeDialog(): void {
    this._isDialogOpened$.next(false);
    this._isShowVisibilityFlag$.next(true);
  }

  hideVisibilityFlag(): void {
    this._isShowVisibilityFlag$.next(false);
  }

  setUploadingDocsFields(data: UploadDocumentData): void {
    this._fieldsUploadingDocuments$.next(data);
  }

  getUploadingDocsFields(): UploadDocumentData | null {
    return this._fieldsUploadingDocuments$.value;
  }

  setDocumentToUpdate(document: VersionedDocument | null): void {
    this._documentToUpdate$.next(document);
  }

  setDocumentToReplace(document: VersionedDocument | null): void {
    this._documentToReplace$.next(document);
  }

  setDocumentToSummarize(document: VersionedDocument | null): void {
    this._documentToSummarize$.next(document);
  }
}
