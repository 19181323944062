<div class="claim-upload-modal modal modelBackground" id="myModal">
    <div class="claim-upload-modal__dialog modal-dialog">
        <div class="claim-upload-modal__content modal-content">
            <div class="modal-header">
                <h6 class="claim-upload-modal__title">{{ 'claim-reporting.upload-popup.file_upload' | translate }}</h6>
                <button class="close shadow-none" type="button" data-dismiss="modal" (click)="clickHide()">
                    <img alt="" src="../../../assets/images/Group 3114.svg">
                </button>
            </div>
            <form>
                <div class="claim-upload-modal__body modal-body">
                    <div class="text-center">
                        <div class="row">
                            <div class="claim-upload-modal__left-content col-md-6">
                                <label
                                    class="claim-upload-modal__label"
                                    for="file-upload">
                                    <img alt="" src="../../../assets/images/Group 2790.png">
                                </label>
                                <input 
                                    id="file-upload"
                                    class="claim-upload-modal__file-input"
                                    type="file"
                                    (click)="clickFile($event)"
                                    (change)="selectFile($event)"
                                    accept="image/png,image/jpg,image/jpeg,image/svg,application/pdf,.png,.svg,.msg"
                                    multiple>
                                <p class="mt-3 claim-upload-modal__accept-info">
                                    {{ 'claim-reporting.upload-popup.select_file_text_propmpt' | translate }}
                                </p>
                            </div>
                            <div class="col-md-6">
                                <ng-container *ngIf="files.length > 0">
                                    <div class="claim-upload-modal__files-list">
                                        <div *ngFor="let rows of files">
                                            <div class="vertically-center gap-10 my-3">
                                                <img src="assets/images/new-icons/Document_icon.svg" alt="">
                                                <span [title]="rows.name" class="bold-text custom-ellipsis t-left">{{rows.name}}</span>
                                                <img alt=""
                                                    src="assets/images/new-icons/Delete icon.svg"
                                                    class="c-pointer"
                                                    (click)="$event.stopPropagation();deleteDocument(rows)">
                                                <img alt=""
                                                    src="assets/images/new-icons/Tickmark_Green.svg">
                                            </div>
                                        </div>
                                    </div>
                                    <p class="claim-upload-modal__error">{{exceedMaxLimit || exceedMaxFiles}}</p>
                                    <app-button (click)="clickSave()">{{ 'claim-reporting.upload-popup.upload_file' | translate }}</app-button>
                                </ng-container>
                                <div *ngIf="files.length == 0" class="claim-upload-modal__message h-100 flex-center-all">
                                    {{ 'claim-reporting.upload-popup.no_file_selected' | translate }}
                                </div>
                                <div class="claim-upload-modal__message h-100 flex-center-all">
                                    {{exceedMaxBulkFiles}}
                                </div>
                            </div>
                        </div>
                        <div class="claim-upload-modal__error claim-upload-modal__error-size col-md-6">{{fileSizeExceed}}</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>