import { UploadDocumentData } from '../components/upload-document-popup/upload-document-popup.model';
import { BaseApiResponse } from './api-base-response.model';

export type GetAllDocumentsResponse = Record<
  'HasErrors' | 'is_toast' | 'is_valid',
  boolean
> &
  Record<
    | 'object_module_id'
    | 'r_code'
    | 'record_id'
    | 'status' // nullable
    | 'time in millis',
    number
  > &
  Record<'message' | 'Errors', string> & { documents: DocumentInfo[] };

export type DocumentInfo = Record<
  | 'upload_document_id'
  | 'document_description'
  | 'document_link'
  | 'document_name'
  | 'upload_via'
  | 'upload_date'
  | 'document_type',
  string
> &
  Record<
    'insurer_id' | 'record_id' | 'updated_at' | 'updated_by' | 'created_by',
    string
  >; // nullable

export type DocumentsResponse = Record<
  'HasErrors' | 'is_toast' | 'is_valid',
  boolean
> &
  Record<
    | 'object_module_id'
    | 'r_code'
    | 'record_id'
    | 'status' // nullable
    | 'time in millis',
    number
  > &
  Record<'message' | 'Errors', string> & { documents: DocumentInformation[] };

export type DocumentInformation = Record<
  | 'documentType'
  | 'description'
  | 'policyNumber'
  | 'productName'
  | 'createdBy'
  | 'uploadDate'
  | 'uuid'
  | 'draftLink'
  | 'content_type'
  | 'documentName',
  string
> &
  Record<
    | 'documentId'
    | 'customerId'
    | 'contractId'
    | 'liztuVnKdnr'
    | 'liztuVsIdnr'
    | 'liztuSaIdnr'
    | 'claimsId'
    | 'insurerId'
    | 'intermediaryId',
    number
  > &
  Record<'visibleForCustomer' | 'draft' | 'hasVersions' | 'summaryPresent', boolean> &
  Record<'sourceUser' | 'targetUser', SourceTargetOptions> & {
    documentSource: DocumentSource;
  };

export enum DocumentSource {
  CONTRACT = 'Contract',
  CLAIMS = 'Claims',
  CUSTOMER = 'Customer',
  INSURER = 'Insurer',
  INTERMEDIARY = 'Intermediary',
  CUSTOMER_ALL = 'CustomerAll',
  MASTER_DATA = 'masterdata',
}

export type DeleteDocumentResponse = Record<
  'HasErrors' | 'is_toast' | 'is_valid',
  boolean
> &
  Record<
    'object_module_id' | 'record_id' | 'r_code' | 'status' | '\'time in millis\'',
    number
  > & // nullable
  Record<'message' | 'Errors', string>;

export type UploadDocumentResponse = Record<
  'HasErrors' | 'is_toast' | 'is_valid',
  boolean
> &
  Record<'message' | 'Errors' | 'document_name' | 'policy_nr', string> &
  Record<
    | 'customer_nr'
    | 'record_id'
    | 'object_module_id'
    | 'r_code'
    | 'status'
    | '\'time in millis\'',
    number
  >;

export type GetSecuredDocumentRequest = Record<'uuid', string>;

export type DocumentSummaryResponse = BaseApiResponse & {
  summary: string;
}

export type DocumentTypeResponse = Record<'id', number>
  & Record<'desc' | 'typeNameEn' | 'typeNameGe', string>;

export enum SourceTargetOptions {
  IC = 'IC',
  CUSTOMER = 'Customer',
  INTERMEDIARY = 'Intermediary',
  OTHERS = 'Others',
  INSURER = 'Insurer',
}

export type UploadOfficeDocument = Omit<
  UploadDocumentData,
  'SA_IDNR' | 'VS_IDNR' | 'VN_KDNR' | 'source'
> &
  Record<'liztuVnKdnr' | 'liztuVsIdnr' | 'liztuSaIdnr', number> & {
    documentSource: DocumentSource;
  } & Record<'description' | 'documentType', string>;
