<mat-dialog-content style="margin: -24px -24px 0px -24px;" id="new-editor">
  <div class="new-editor-heading" style="border-bottom: 1px solid #d5d5d5;">
    <h2 matDialogTitle style="float: left;
        width: 50%;
        font-size: 17px;color: #272727;;margin-bottom:2px" class="mat-headline center-align">
      {{'contact-manager.New_editor_heading' | translate}}</h2>
    <button style="float: right;
        color: #fff;
        margin-top: -2px;" (click)="onNoClick()">
      <mat-icon>
        <img alt="" style="vertical-align: sub;" src="../../../../assets/images/Group 3298.svg">
      </mat-icon>
    </button>
  </div>

  <div class="d-flex flex-row row-height">
    <div class="flex-fill">
      <mat-form-field appearance="fill" [ngClass]="{'disabled': data.fromWhich == 'add'}"
        style="float: left; width: 49%;">
        <mat-label>{{'contact-manager.Mandant_text' | translate}}</mat-label>

        <input [(ngModel)]="data.mandantname" [disabled]="true" *ngIf="data.fromWhich !== 'edit'"
          placeholder=" {{'contact-manager.Mandant_placeholder' | translate}}" class="input" matInput>
        <mat-select required [(ngModel)]="data.mandantname" name="mandant" *ngIf="data.fromWhich == 'edit'"
          placeholder="{{'contact-manager.Mandant_placeholder' | translate}}"
          (selectionChange)="saveMandant($event);search.value = ''" (openedChange)="search.value = ''">
          <input type="text" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}"
            matInput #search>
          <mat-option *ngFor="let mandant of validationService._filter(allDivision, 'division_name', search.value)"
            [value]="mandant.division_name">
            {{mandant.division_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" [ngClass]="{'disabled': data.fromWhich == 'add' , 'red': actortype === true}"
        style="float:right; width: 49%;">
        <mat-label>{{'contact-manager.Empfängerkreis_text' | translate}}</mat-label>
        <input required [(ngModel)]="data.actortypename" *ngIf="data.fromWhich !== 'edit'"
          placeholder="{{'contact-manager.Empfängerkreis_placeholder' | translate}}" [disabled]="true" class="input"
          matInput>
        <mat-select required [(ngModel)]="data.actortypename" name="actorType" *ngIf="data.fromWhich == 'edit'"
          placeholder="{{'contact-manager.Empfängerkreis_placeholder' | translate}}"
          (selectionChange)="saveActorType($event)">
          <mat-option *ngFor="let actorType of actorTypes" [value]='actorType.actor_type_name'>
            {{actorType.actor_type_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="d-flex flex-row row-height">
    <div class="flex-fill">
      <mat-form-field appearance="fill" [ngClass]="{'disabled': data.fromWhich == 'add' , 'red': actor === true}"
        style="float: left; width: 100%; width: 49%;">
        <mat-label> {{'contact-manager.Empfängername_text' | translate}}</mat-label>

        <input required [(ngModel)]="data.actorname" class="input" *ngIf="data.fromWhich !== 'edit'" matInput
          placeholder="{{'contact-manager.Empfängername_placeholder' | translate}}" [disabled]="true">
        <mat-select required [(ngModel)]="data.actorname" name="actor" *ngIf="data.fromWhich == 'edit'"
          placeholder="{{'contact-manager.Empfängername_placeholder' | translate}}"
          (selectionChange)="saveActor($event);searchActor.value = ''" (openedChange)="searchActor.value = ''">
          <input [hidden]="actors.length <= 6" type="text" maxlength="20" class="searchSelect"
            placeholder="{{'header.Suche_tooltip' | translate}}" matInput #searchActor>
          <mat-option *ngFor="let actor of validationService._filter(actors, 'actor_name', searchActor.value)"
            [value]='actor.actor_name'>
            {{actor.actor_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" [ngClass]="{'disabled': data.fromWhich == 'add'}"
        style="float:right; width: 49%;">
        <mat-label> {{'contact-manager.Tochtergesellschaft_text' | translate}}</mat-label>

        <input [(ngModel)]="data.subsidiaryname" class="input" matInput *ngIf="data.fromWhich !== 'edit'"
          [disabled]="true" placeholder="{{'contact-manager.Tochtergesellschaft_placeholder' | translate}}">
        <mat-select [required]="companies?.length > 0" [(ngModel)]="data.subsidiaryname" name="subsidiary" *ngIf="data.fromWhich == 'edit'"
          (selectionChange)="saveSubsidiary($event)"
          placeholder="{{'contact-manager.Tochtergesellschaft_placeholder' | translate}}">
          <mat-option *ngFor="let subsidiary of companies" [value]='subsidiary.customer_name'>
            {{subsidiary.customer_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="d-flex flex-row">
    <div class="flex-fill">
      <mat-form-field [ngClass]="{'red': vorgang === true}" appearance="fill" style="float: left; width: 49%;    ">
        <mat-label> {{'new-editor.VORGÄNGE_text' | translate}}</mat-label>
        <mat-select (selectionChange)="typeVorgange($event)" [(ngModel)]="data.process" required>
          <mat-option *ngFor="let process of processes" [value]='process.operation_name'>
            {{process.operation_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [ngClass]="{'red': sparte === true}" appearance="fill" style="width: 49%;float:right;    ">
        <mat-label> {{'new-editor.SPARTE_text' | translate}}</mat-label>
        <mat-select (selectionChange)="typeSparte($event); searchSparte.value = ''" [(ngModel)]="data.sparte" required
          (openedChange)="searchSparte.value = ''">
          <input type="text" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}"
            matInput #searchSparte>
          <mat-option *ngFor="let sparte of validationService._filter(sparte_array, 'description', searchSparte.value)"
            matTooltipClass="testtooltip" matTooltip="{{getSparteNamesList(sparte.group_data)}}"
            [ngClass]="{'redStrip': sparte.is_sparte_group === true}" class="sparteoption" [value]="sparte.description">
            {{sparte.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>


  <div class="d-flex flex-row row-height">
    <div class="flex-fill">
      <mat-form-field [ngClass]="{'red': product === true}" *ngIf="!Product__PreSelected" class="role" appearance="fill"
        style="width: 49%;float:left;height:42px;    ">
        <mat-label> {{'new-editor.PRODUKT_text' | translate}}</mat-label>
        <mat-select (selectionChange)="typeProduct($event)" [(ngModel)]="data.product"
          [disabled]="product_disabled == true" required>
          <mat-option *ngFor="let item of productList" [value]="item.productName">
            {{item.productName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [ngClass]="{'red': product === true}" *ngIf="Product__PreSelected" class="role" appearance="fill"
        style="width: 49%;float:left;height:42px;    ">
        <mat-label> {{'new-editor.PRODUKT_text' | translate}}</mat-label>
        <mat-select (selectionChange)="typeProduct($event)" [(ngModel)]="selectedProduct"
          [disabled]="product_disabled == true" required>
          <mat-option *ngFor="let item of productList" [value]="item.productName">
            {{item.productName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="!isCustomerAdmin" [ngClass]="{'red': bereich === true}" class="Bereich"
        appearance="fill" style="width: 49%;float:right;height:42px;">
        <mat-label> {{'new-editor.BEREICH_text' | translate}}</mat-label>
        <mat-select (selectionChange)="typeBereich($event); searchArea.value = ''" [(ngModel)]="data.cm_area" required
          (openedChange)="searchArea.value = ''">
          <input type="text" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}"
            matInput #searchArea>
          <mat-option *ngFor="let item of validationService._filter(bereichList, 'cmArea', searchArea.value)"
            [value]="item.cmArea">
            {{item.cmArea}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </div>

  <div *ngIf="!isCustomerAdmin" class="d-flex flex-row row-height">
    <div class="flex-fill">
      <mat-form-field [ngClass]="{'red': von === true}" appearance="fill" style="width: 49%;float:left;    ">
        <mat-label> {{'new-editor.VON_text' | translate}} <span *ngIf="serchfind == false"> : {{von_validation |
            multilingual | translate}}</span></mat-label>
        <input [(ngModel)]="data.from" placeholder="" (blur)="typeVon($event.target.value)" class="input" matInput
          [disabled]="von_disable == true" required>
      </mat-form-field>

      <mat-form-field [ngClass]="{'red': bis === true}" appearance="fill" style="width: 49%;float:right;    ">
        <mat-label> {{'new-editor.BIS_text' | translate}} <span *ngIf="serchfindto == false"> : {{bis_validation |
            multilingual | translate}}</span></mat-label>
        <input [(ngModel)]="data.to" placeholder="" class="input" (blur)="typeBis($event.target.value)" required
          matInput [disabled]="bis_disable == true">
      </mat-form-field>
    </div>
  </div>


  <div class="d-flex flex-row row-height">
    <div class="flex-fill">
      <mat-form-field [ngClass]="{'red': extern === true}" class="language" appearance="fill"
        style="width: 49%;float:left;height:42px;">
        <mat-label> {{'new-editor.EXTERN_text' | translate}}</mat-label>
        <mat-select [(ngModel)]="data.extern" (selectionChange)="typeExtern($event); searchExtern.value=''" required
          [disabled]="isCustomerUser == true || extern_disable == true" (openedChange)="searchExtern.value = ''">
          <input type="text" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}"
            matInput #searchExtern>
          <mat-option value="reset" *ngIf="extern_id !== '' || data.extern !== ''" style="width: 100%;
                font-size: 13px;
                color: #031d2f;
                outline: none;
                border: none;" (click)="reserExtern()">{{'contact-manager.Reset_Text' | translate}}
            {{'contact-manager.Extern' | translate}} </mat-option>
          <mat-option *ngFor="let item of validationService._filter(externList, 'userRoleName', searchExtern.value)"
            [value]="item.userRoleName">
            {{item.userRoleName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [ngClass]="{'red': bearbeiter === true}" class="role" appearance="fill"
        style="width: 49%;float:right;height:42px;    ">
        <mat-label>{{'new-editor.BEARBEITER_text' | translate}}</mat-label>
        <mat-select [(ngModel)]="data.editor" (selectionChange)="typeBearbeiter($event);searchEditor.value = ''"
          required [disabled]="editor_disable" (openedChange)="searchEditor.value = ''">
          <input type="text" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}"
            matInput #searchEditor>
          <mat-option value="reset" *ngIf="editor_id !== '' || data.editor !== '' " style="width: 100%;
                font-size: 13px;
                color: #031d2f;
                outline: none;
                border: none;" (click)="reserBearbeiter()">{{'contact-manager.Reset_Text' |
            translate}} {{'contact-manager.BEARBEITER' | translate}} </mat-option>
          <mat-option *ngFor="let item of validationService._filter(editorList, 'sortedName', searchEditor.value)"
            [value]="item.sortedName">
            {{item.sortedName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </div>


  <div class="d-flex flex-row row-height">
    <div class="flex-fill">
      <mat-form-field [ngClass]="{'red': email === true}" appearance="fill" style="width: 49%;float:left;    ">
        <mat-label>{{'new-editor.EMAIL_text' | translate}}</mat-label>
        <input [(ngModel)]="data.email" matInput type="email" placeholder="" (blur)="typeEmail()" class="input"
          disabled>
      </mat-form-field>
      <mat-form-field [ngClass]="{'red': telephone === true}" appearance="fill" style="width: 49%;float:right;">
        <mat-label> {{'new-editor.TELEFON_text' | translate}}</mat-label>
        <input [(ngModel)]="data.telephone" matInput type="tel" placeholder="" (blur)="typeTelefon()" class="input"
          disabled>
      </mat-form-field>

    </div>
  </div>

  <p *ngIf="extern_editor_message" style="color:#FF1010;font-size: 12px;"> {{'new-editor.Bittewählen_text' | translate}}
  </p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button *ngIf="data.fromWhich !== 'edit'" style="font-size: 15px;color: #272727;border: solid 1px #272727;padding: 0px 22px 0px 22px;
    border-radius: 2px;" (click)="onNoClick()" mat-button matDialogClose="cancel"
    color="primary">{{'new-editor.zuruk_text' | translate}}</button>
  <button style="color: #272727;background: #ff9000;padding: 0px 22px 0px 22px;
    border-radius: 2px;font-size: 15px;" mat-button type="submit" (click)="save(data)"
    color="warn">{{'new-editor.ADD_new_cm_data_text' | translate}}</button>
</mat-dialog-actions>
