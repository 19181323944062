import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Api } from '@shared/constants/api.constants';
import { Api_URL } from '@shared/constants/url.constants';
import { Injectable } from '@angular/core';
import {
  ContractListResponse,
  ContractsAndProductNameResponse,
  UpdateResponse,
} from '@shared/models/contract.model';
import { SortConfig } from '@shared/models/sort.model';

@Injectable({ providedIn: 'root' })
export class ContractApiService {
  constructor(private readonly _httpClient: HttpClient) {}

  getContracts(
    customerId: string,
    userId: string,
    hideVehicleContracts: boolean,
    showGroupContracts: boolean,
    states: string[],
    page: number,
    contractsPerPage: number,
    sorting: SortConfig
  ): Observable<ContractListResponse> {
    const contractData = {
      customerId,
      userId,
      hideVehicleContracts,
      showGroupContracts,
      states,
      page,
      contractsPerPage,
      sorting,
    };
    return this._httpClient.post<ContractListResponse>(
      Api_URL.IC_API_URL + Api.GET_CONTRACT_LIST,
      contractData
    );
  }

  getSubordinateContracts(
    contractId: number
  ): Observable<ContractListResponse> {
    const params = new HttpParams().append('contractId', String(contractId));
    return this._httpClient.get<ContractListResponse>(
      Api_URL.IC_API_URL + Api.GET_SUBORDINATE_CONTRACTS,
      { params }
    );
  }

  getUnbundledContracts(
    customerId: string,
    parentId: number
  ): Observable<ContractListResponse> {
    const params = new HttpParams()
      .append('customerId', customerId)
      .append('parentId', String(parentId));
    return this._httpClient.get<ContractListResponse>(
      Api_URL.IC_API_URL + Api.GET_UNBUNDLED_CONTRACTS,
      { params }
    );
  }

  attachContract(
    parentId: number,
    childId: number
  ): Observable<UpdateResponse> {
    const attachData = {
      parentId,
      childId,
    };
    return this._httpClient.post<UpdateResponse>(
      Api_URL.IC_API_URL + Api.ATTACH_CONTRACT,
      attachData
    );
  }

  detachContract(
    parentId: number,
    childId: number
  ): Observable<UpdateResponse> {
    const attachData = {
      parentId,
      childId,
    };
    return this._httpClient.post<UpdateResponse>(
      Api_URL.IC_API_URL + Api.DETACH_CONTRACT,
      attachData
    );
  }

  getContractsAndProductName(
    customerId: string
  ): Observable<ContractsAndProductNameResponse> {
    const params = new HttpParams().append('customer_id', customerId);

    return this._httpClient.get<ContractsAndProductNameResponse>(
      Api_URL.IC_API_URL + Api.GET_CONTRACT_LIST_AND_PRODUCT_NAMES, 
      { params }
    );
  }
}
