import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DocumentManagementService } from '@core/services/document-management.service';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  DocumentSource,
  DocumentTypeResponse,
  SourceTargetOptions,
  UploadDocumentResponse,
} from '../../models/document-management.model';
import {
  UploadDocumentData,
  DIRECTION_OPTIONS,
  TypeOption,
} from './upload-document-popup.model';
import { LOCALS } from '@shared/constants/local.constants';
import { VersionedDocument } from '../documents-table/document-table.model';
import { filterNil } from '../../utils/filter-nil.pipe';
import { BaseComponent } from '../base.component';
import { UserService } from '@core/user.service';
import { Utils } from '../../utils/utils';

const MAX_FILE_SIZE = 35840000;

@Component({
  selector: 'app-upload-document-popup',
  styleUrls: ['upload-document-popup.component.scss'],
  templateUrl: 'upload-document-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDocumentPopupComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  form: FormGroup;
  uploadedFile: File;
  readonly sources = DocumentSource;
  readonly directionOptions = DIRECTION_OPTIONS;
  readonly isShouldOpenDialog$ =
    this._documentManagementService.isDialogOpened$;
  readonly isShowVisibilityFlag$ =
    this._documentManagementService.isShowVisibilityFlag$;

  private readonly _title$: BehaviorSubject<string> = new BehaviorSubject('');
  readonly title$: Observable<string> = this._title$.asObservable();

  private readonly _source$: BehaviorSubject<DocumentSource | null> =
    new BehaviorSubject<DocumentSource | null>(null);
  readonly source$ = this._source$.asObservable();

  private readonly _isUpdateOperation$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  readonly isUpdateOperation$ = this._isUpdateOperation$.asObservable();

  private readonly _isReplaceOperation$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  readonly isReplaceOperation$ = this._isReplaceOperation$.asObservable();

  private readonly _isSummarizeOperation$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  readonly isSummarizeOperation$ = this._isSummarizeOperation$.asObservable();

  readonly documentTypes$: Observable<TypeOption[]> = this._source$.pipe(
    switchMap((source: DocumentSource) => {
      const locale = this._userService.getLocale();
      if (source) {
        return this._documentManagementApiService.getDocumentTypes(source).pipe(
          map((types: DocumentTypeResponse[]) =>
            types
              .map(({ typeNameEn, typeNameGe }) => ({
                label: locale === LOCALS.GERMAN ? typeNameGe : typeNameEn,
                value: typeNameGe,
              }))
              .sort((a: TypeOption, b: TypeOption) =>
                a.label > b.label ? 1 : -1
              )
          )
        );
      }
      return of([]);
    }),
    tap(() => this._updateForm())
  );

  private _documentInfoForUpdating: VersionedDocument;

  constructor(
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService,
    private readonly _documentManagementService: DocumentManagementService,
    private readonly _documentManagementApiService: DocumentManagementApiService,
    private readonly _fb: FormBuilder,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this._watchUploadingDocumentData();
    this._watchUpdatingDocument();
    this._watchReplacingDocument();
    this._watchObtainingSummary();
  }

  onSelectFile(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    if (file.size > MAX_FILE_SIZE) {
      this._toastService.showToastMessage(
        document,
        this._translateService.instant('contracts.max_file_size')
      );
      this.form.controls.file.reset();
    } else {
      this.uploadedFile = file;
    }
  }

  removeFile(): void {
    this.uploadedFile = null;
    this.form.controls.file.reset();
  }

  closeDialog(): void {
    this.form.reset();
    this.form.controls.type?.setValue('');
    this.uploadedFile = null;
    this._documentInfoForUpdating = null;
    this._isUpdateOperation$.next(false);
    this._isReplaceOperation$.next(false);
    this._isSummarizeOperation$.next(false);
    this._documentManagementService.setDocumentToUpdate(null);
    this._documentManagementService.setDocumentToReplace(null);
    this._documentManagementService.setDocumentToSummarize(null);
    this._documentManagementService.closeDialog();
  }

  updateDocument(): void {
    const { description, visibility, direction, type } = this.form.controls;
    const { source, target } = Utils.getSourceAndTargetByLabel(direction.value);
    this._documentManagementApiService
      .updateMetaData(
        description.value,
        source,
        target,
        this._documentInfoForUpdating.documentId,
        visibility.value,
        type.value
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._toastService.showToastMessage(
          document,
          this._translateService.instant('objectModule.documentUploadMessage')
        );
        this.closeDialog();
        this._documentManagementService.refresh();
      });
  }

  uploadDocument(): void {
    const { description, type, visibility, direction } = this.form.controls;
    const { source: sourceDirection, target: targetDirection } =
      Utils.getSourceAndTargetByLabel(direction.value);
    const {
      SA_IDNR,
      VS_IDNR,
      VN_KDNR,
      contractId,
      userId,
      source,
      customerId,
      policyNumber,
      documentNumber,
      insurerId,
      damageId,
      intermediaryId,
      documentCreationDate,
      documentIdentifier,
    } = this._documentManagementService.getUploadingDocsFields();
    this._documentManagementApiService
      .documentUpload(
        [this.uploadedFile],
        userId,
        type.value,
        documentNumber,
        policyNumber,
        description.value,
        customerId,
        VN_KDNR,
        SA_IDNR,
        VS_IDNR,
        contractId,
        documentCreationDate,
        documentIdentifier,
        damageId,
        insurerId,
        intermediaryId,
        sourceDirection,
        targetDirection,
        visibility.value,
        source
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(({ HasErrors, message }: UploadDocumentResponse) => {
        this._toastService.showToastMessage(
          document,
          HasErrors
            ? message
            : this._translateService.instant(
                'objectModule.documentUploadMessage'
              )
        );
        this.closeDialog();
        this._documentManagementService.refresh();
      });
  }

  replaceDocument(): void {
    const { description, type } = this.form.controls;
    this._documentManagementApiService
      .addVersions(
        this.uploadedFile,
        this._documentInfoForUpdating.documentId,
        description.value,
        type.value
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._toastService.showToastMessage(
          document,
          this._translateService.instant('objectModule.documentUploadMessage')
        );
        this.closeDialog();
        this._documentManagementService.refresh();
      });
  }

  private _watchUploadingDocumentData(): void {
    this._documentManagementService.fieldsUploadingDocuments$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe((fields: UploadDocumentData) => {
        this._createUploadingForm();
        const { source, policyNumber } = fields;
        this._setTitle(source, policyNumber);
        this._setSource(source);
      });
  }

  private _getTitle(source: DocumentSource, policyNumber: string): string {
    switch (source) {
      case DocumentSource.CONTRACT: {
        return (
          this._translateService.instant(
            'contracts.DokumenteHochladenContract_title'
          ) + ` ${policyNumber}`
        );
      }

      case DocumentSource.CLAIMS: {
        return (
          this._translateService.instant('claims.DokumenteHochladen_title') +
          ` ${policyNumber}`
        );
      }

      default:
        return this._translateService.instant(
          'contracts.DokumentHochladen_text'
        );
    }
  }

  private _setTitle(source: DocumentSource, policyNumber: string): void {
    this._title$.next(this._getTitle(source, policyNumber));
  }

  private _setSource(source: DocumentSource): void {
    this._source$.next(source);
  }

  private _watchUpdatingDocument(): void {
    this._documentManagementService.documentToUpdate$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe((documentInfo: VersionedDocument) => {
        this._setSource(documentInfo.documentSource);
        this._isUpdateOperation$.next(true);
        this._updateTitle(false);
        this._createUpdatingForm();
        this._documentInfoForUpdating = documentInfo;
      });
  }

  private _watchReplacingDocument(): void {
    this._documentManagementService.documentToReplace$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe((documentInfo: VersionedDocument) => {
        this._setSource(documentInfo.documentSource);
        this._isReplaceOperation$.next(true);
        this._updateTitle(true);
        this._createReplacingForm();
        this._documentInfoForUpdating = documentInfo;
      });
  }

  private _watchObtainingSummary(): void {
    this._documentManagementService.documentToSummarize$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe((documentInfo: VersionedDocument) => {
        this._documentManagementApiService
          .getDocumentSummary(documentInfo.uuid, documentInfo.summaryPresent)
          .subscribe((data) => {
            this._setSource(documentInfo.documentSource);
            this._isSummarizeOperation$.next(true);
            this._summaryTitle();
            this._createSummaryForm(data?.summary ?? "Summary Data Not Received");
          });
      });
  }

  private _createSummaryForm(summary: string): void {
    this.form = this._fb.group({
      summary: new FormControl(summary ?? ""),
    });
  }

  private _createReplacingForm(): void {
    this.form = this._fb.group({
      file: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    });
  }

  private _createUpdatingForm(): void {
    this.form = this._fb.group({
      type: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      visibility: new FormControl('', Validators.required),
      direction: new FormControl('', Validators.required),
    });
  }

  private _createUploadingForm(): void {
    this.form = this._fb.group({
      type: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      file: new FormControl('', Validators.required),
      visibility: new FormControl(false, Validators.required),
      direction: new FormControl('', Validators.required),
    });
  }

  private _getDirectionBySourceTarget(
    source: SourceTargetOptions,
    target: SourceTargetOptions
  ): string {
    return this.directionOptions.find(
      option => option.value.source === source && option.value.target === target
    )?.label;
  }

  private _updateTitle(isReplaceOperation: boolean): void {
    this._title$.next(
      `${this._translateService.instant(
        isReplaceOperation
          ? 'upload-document.replacing_document_title'
          : 'upload-document.updating_document_title'
      )}`
    );
  }

  private _summaryTitle(): void {
    this._title$.next(
      `${this._translateService.instant('upload-document.show_summary_title')}`
    );
  }

  private _updateForm(): void {
    if (!this._isUpdateOperation$.value && !this._isReplaceOperation$.value) {
      return;
    }
    const {
      documentType,
      targetUser,
      sourceUser,
      visibleForCustomer,
      description,
    } = this._documentInfoForUpdating;
    this.form.controls.type?.setValue(documentType);
    this.form.controls.description?.setValue(description);
    this.form.controls.visibility?.setValue(visibleForCustomer);
    this.form.controls.direction?.setValue(
      this._getDirectionBySourceTarget(sourceUser, targetUser) || ''
    );
  }
}
